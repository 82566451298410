@import "../config/typography";
@import "../utils/color";

p {
  margin-bottom: 1.3em;
}

// Loop through each heading size
@each $name, $size in $headingFontSizes {
  #{$name} {
    font: $fontNormal $size $fontHeadings;
    line-height: 1.2;
    margin: 1.414em 0 0.5em;
  }
}

@each $name, $size in $headingDesktopFontSizes {
  #{$name} {
    @include desktop {
      line-height: 1.35;
      font-size: $size;
    }
  }
}

h1 {
  font-weight: $fontMedium;
  margin: 0 0 0.5em;
  color: color(primary);
  letter-spacing: 0.02em;
}

h2 {
  color: color(secondary);
}

h3,
h4 {
  color: color(text, dark);
}

h5,
h6 {
  line-height: 1.4;
  color: color(text);
  font-weight: $fontNormal;
}

small,
h6 {
  margin: 0.25em 0;
}

img,
svg {
  max-width: 100%;
}

pre {
  font-family: monospace, monospace;
  font-size: 0.85rem;
  color: color(text, dark);
  background: color(primary, background);
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  padding: 8pt 16pt;
  width: 100%;
  //max-width: 85%; // Remove later
  overflow: auto;
  border: 1px solid #eee;
}

@import "styles/common";

.button {
  //color: color(primary, dark);
  color: color(grey);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(34px);
  height: rem(28px);

  @include desktop {
    color: color(primary);
  }
}

@import "styles/common";

.tooltip {
  background: rgba(color("grey", "darkest"), 0.9);
  color: color("text", "alt");
  font-size: 12px;
  padding: 3px 12px;
  //height: 30px;
  border-radius: 3px;
  z-index: 100000;
  pointer-events: none;
  svg path {
    fill: rgba(color("grey", "darkest"), 0.9);
  }
}

.arrow {
  top: 100%;
  left: 47.3828px;
  position: absolute;
  will-change: top, bottom, left, right;
  transform: translateX(-50%);
  path {
    fill: rgba(color("grey", "darkest"), 0.9);
  }
}

@import "styles/common";

.root {
  .label {
    //margin-bottom: 2pt;
    display: block;
    text-align: left;
    color: color(grey, dark);
    font-size: 0.9em;
    font-weight: $fontBold;

    span {
      color: color(text);
      font-weight: normal;
      font-style: italic;
    }
  }
  &.error {
    .label {
      color: color(text, danger);
      font-weight: bold;
    }
  }

  &.score--1 {
    .passwordWrapper {
      padding-bottom: 0;
    }
    .container {
      bottom: 0.5px;
    }
    .bar {
      width: 0;
    }
    .strength {
      opacity: 0;
      color: color(text, light);
      font-weight: $fontNormal;
      left: 0;
      text-align: left;
    }
  }
  &.score-0 {
    .passwordWrapper input {
      border-color: color(danger, dark);
    }
    .bar {
      width: 2%;
      background: color(danger, dark);
    }
    .strength {
      left: 0;
      text-align: left;
      color: color(danger, darker);
    }
  }
  &.score-1 {
    .passwordWrapper input {
      border-color: color(danger);
    }
    .bar,
    .strength {
      width: 25%;
    }
    .bar {
      background: color(danger);
    }
    .strength {
      color: color(danger, dark);
    }
  }
  &.score-2 {
    .passwordWrapper input {
      border-color: color(warning);
    }
    .bar,
    .strength {
      width: 50%;
    }
    .bar {
      background: color(warning);
    }
    .strength {
      color: color(warning, dark);
    }
  }
  &.score-3 {
    .passwordWrapper input {
      border-color: color(info);
    }
    .bar,
    .strength {
      width: 75%;
    }
    .bar {
      background: color(info);
    }
    .strength {
      color: color(info);
    }
  }
  &.score-4 {
    .passwordWrapper input {
      border-color: color(success);
    }
    .bar {
      width: 102%;
      background: color(success);
    }
    .strength {
      color: color(success);
      width: 99%;
    }
  }
}
.passwordWrapper {
  position: relative;
  padding-bottom: 20px;
  transition: padding-bottom 200ms ease-in-out;
  .input {
    input {
      padding-bottom: 18px;
    }
  }
}
.container {
  position: absolute;
  left: 0.5px;
  right: 0.5px;
  bottom: 20.5px;
  top: 1px;
  border-radius: 8px;
  pointer-events: none;
  overflow: hidden;
  transition: bottom 200ms ease-in-out;

  @include psuedo--before {
    height: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparentize(color(grey), 0.6);
  }
}

.bar {
  height: 5px;
  border-radius: 0 4px 4px 0;
  transition: 200ms ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  span {
  }
}
.strength {
  position: absolute;
  left: 0;
  bottom: 0;
  //min-width: 200px;
  transition: 200ms ease-in-out;
  text-align: right;
  font-weight: $fontMedium;
  font-size: rem(12px);
}
.help {
  color: color(text, light);
  font-size: rem(12px);
  pointer-events: all;
  position: absolute;
  right: 0;
  bottom: 0;
}
.feedback {
  margin-bottom: 0;
  color: color(warning, dark);
  svg {
    color: color(warning, dark);
  }
}
.suggestion {
  margin-bottom: 0;
  color: color(info, dark);
  svg {
    color: color(info, dark);
  }
}
.errorFeedback {
  margin-bottom: 4px;
}
.strengthInput {
  //position: absolute;
  //left: -9999px;
  height: 0;
  border: 0;
  padding: 0;
  margin-bottom: 1.4em;
  clip-path: polygon(0 0);
  tab-index: -1;
  opacity: 0;
}

@import "styles/common";

.noSpacingMenuItem {
  padding: 0 !important;
}

.menuItemButton {
  width: 100%;
  cursor: pointer;
  padding: 4px 8px 4px 16px;
}

.header {
  border-radius: 12px 4px 0 0;
  background-color: transparentize(color(primary, lightest), 0.8);
  padding: 12px 20px 10px 4px;
  margin: -6px 0 4px 0;
  border-bottom: 1px solid color(grey, lighter);
  @include flex(center, center);
  .icon {
    color: color(primary);
    margin: 0 12px 0 8px;
    border: 2px solid color(primary, lighter);
    padding: 2px;
    width: 28px;
    height: 28px;
    border-radius: 20px;
  }
  .title {
    color: color(primary, darkest);
    font-weight: $fontMedium;
    line-height: 1.2;
    span {
      color: color(text);
      font-weight: $fontNormal;
      font-style: italic;
      font-size: 0.9em;
    }
  }
}
.menuSection {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8em;
  color: color(text, light);
}

@import "styles/common";
.root {
  h2 {
    color: color("secondary", "dark");
  }
  hr {
    border-top: 1px solid color("grey", "lighter");
  }
}
.header,
.pageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include desktop {
    align-items: start;
    flex-direction: row;
  }
}
.pageHeader {
  padding: 60px 0 20px 0;
  @include desktop {
    padding-top: 40px;
  }
}
.header {
  margin-top: 20px;
  h2 {
    margin: 0;
    flex-grow: 1;
  }
}
.userIcon {
  color: color("primary");
  margin-right: 18px;
  margin-top: 4px;
  border: 2px solid color("primary", "lighter");
  padding: 6px;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  display: none;
  @include desktop {
    display: inherit;
  }
}
.userText {
  flex-grow: 1;
  text-align: center;
  padding: 12px 0;
  @include desktop {
    padding: 0;
    text-align: left;
  }
  h1 {
    font-weight: $fontNormal;
    margin-bottom: rem(8px);
  }

  .item {
    @include flex(flex-start, flex-start, row, wrap);
    padding-bottom: rem(8px);
    width: fit-content;
    margin: 0 auto;
    @include desktop {
      margin: 0;
    }
    &,
    .text a {
      color: rgba(color("text"), 0.7);
      font-size: rem(16px);
    }
    .icon {
      color: color("primary", "light");
      margin: rem(4px) rem(12px) rem(4px) rem(6px);
      font-size: rem(20px);
    }
    .text {
      flex-grow: 1;
      a {
        @include desktop {
          &:hover,
          &:focus {
            text-decoration: underline;
            color: color("secondary", "dark");
          }
        }
      }
    }
    .mfa {
      margin-left: 12px;
      svg {
        font-size: rem(18px);
      }
    }
    .orgChip {
      margin: 0 4px 4px 0;
    }
  }
}
.actionContainer {
  @include flex--column();
  .actions {
    @include flex(center, center);
    @include desktop {
      @include flex(center, flex-end);
    }
  }
}
.actions {
  @include flex(center, flex-end);
}
.mfaSwitch {
  padding: 12px 0;
  @include flex(center);
  span {
    font-weight: $fontMedium;
    padding-right: 12px;
  }
}
.permissionsTable {
  .permissionSectionHeader:not(:first-child) {
    margin-top: 16px;
  }
  .actions {
    margin: 12px 12px 0 0;
  }
}

.permissionSectionHeader {
  padding: 6px;
  border-bottom: 1px solid color("grey", "lighter");
  .headerContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
    .headerTextContainer {
      flex-grow: 1;
      padding-right: 12px;
      .permissionDescription {
        padding: 6px 0 3px 0;
        font-style: italic;
        color: color(text, light);
        font-size: rem(13px);
      }
    }
    .headerActions {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-grow: 0;
      flex-shrink: 0;
      @include desktop {
        flex-direction: row;
      }
    }
    .title {
      font-weight: $fontMedium;
      flex-grow: 1;
      margin: 0;
      font-size: rem(18px);
      color: color("text");
    }
  }

  .counts {
    flex-shrink: 0;
    font-weight: $fontBold;
    span {
      font-weight: $fontNormal;
      opacity: 0.7;
    }
  }
}

.sectionContent {
  background-color: transparentize(color(grey, lightest), 0.7);
  padding-bottom: 12px;
  border-radius: 0 0 8px 8px;
}
.permissionDescription {
  padding: 8px 12px 0 8px;
  color: color("text");
}
.permissionDetail {
  margin-top: 6px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 200ms ease-in-out;
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    background-color: transparentize(color("grey", "lightest"), 0.3);
  }
  &.active {
    .label {
      color: color("info");
    }
    .icon {
      color: color("info");
    }
  }
  > div:first-child {
    flex-grow: 1;
  }
  .label {
    margin: 0;
    font-weight: $fontMedium;
    font-size: rem(16px);
    color: color("danger", "dark");
  }
  .description {
    font-style: italic;
    color: color("text", "light");
    font-size: rem(13px);
  }
  .icon {
    margin: 0 12px;
    color: color("danger");
  }
}

.statusAction {
  margin: 0 2px;
  cursor: pointer;
  //color: color(info, darker);
  height: 38px;
  width: 38px;
  border-radius: 20px;
  padding: 3px;
  transition: color 200ms ease-in-out;
  //background-color: color(info, lightest);
  @include flex(center, center);
}
.status {
  font-weight: $fontMedium;
  span {
    font-weight: $fontBold;
    color: color("text");
    margin-right: 8px;
  }
}

.label,
.title {
  display: flex;
  .readonlyChip {
    margin-left: 6px;
    flex-grow: 0;
  }
}

.deletedUser {
  background-color: color(danger, lightest);
  border-radius: 4px;

  h4 {
    padding: 16px 0;
    color: color(text, danger);
    margin: 0;
    text-align: center;
    font-weight: $fontMedium;
    @include flex(center, center);
    svg {
      margin-right: 6px;
    }
  }
}

.modalContent {
  flex-grow: 1;
  padding: 16px 24px;
}

@import "styles/common";

$radius: 30px;
$dim: 14px;
$border: 2px;
$transition: 0.15s ease-in;
$transition-property: background-color, border-color, box-shadow, color,
  transform, opacity;

.button {
  padding: 3px 6px;
  font: $fontNormal $fontSize $fontBody;
  letter-spacing: 0.06rem;
  line-height: $fontLineHeight;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    .border {
      //box-shadow: color("primary", "darkest") 0px 0px 0px 1px;
      margin: 0 3px;
    }
  }
  &:hover,
  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
.wrap {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  //box-sizing: border-box;
  border: solid $border transparent; /* !importanté */
  border-radius: 8px;
  margin: 0 3px 8px 0;
}
.chip {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 6px 28px;
  //margin: 0 2pt 2pt 0;
  background: transparent;
  color: #fff;
  font-weight: $fontBold;
  transition: $transition;
  transition-property: $transition-property;
  border-radius: 8px;
  &.outline {
    background: $background;
  }
  background-clip: padding-box; /* !importanté */
  &.alt-bg {
    background: $backgroundAlt;
  }
  &.dotButton {
    padding: 6px ($dim + 6px) 6px $dim;
  }
}

.border {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //z-index: -1;
  //margin: -$border; /* !importanté */
  margin: 1px 4px;
  //border-radius: inherit; /* !importanté */
  border-radius: 10px;
  transition: $transition;
  transition-property: $transition-property;
}

.rounded {
  //border-radius: $radius;
  .border {
    border-radius: $radius;
  }
  .chip {
    border-radius: $radius;
  }
}

.dot {
  height: $dim;
  width: $dim;
  border-radius: $radius;
  margin-right: $dim;
  transition: $transition;
}

@mixin chip-format($text, $grad-start, $grad-stop, $dot) {
  &:hover,
  &:focus {
    .chip,
    .border {
      transform: scale(1.02);
    }
    .border {
      box-shadow: 0 4px 8px rgba(color("grey", "darker"), 0.2);
    }
    &:active {
      .chip,
      .border {
        transform: scale(0.996);
        box-shadow: inset 0 4px 8px rgba(color("grey", "dark"), 0.1);
      }
    }

    .chip {
      //color: $text;
      //background: $background;
      .dot {
        background: $dot;
        opacity: 1;
      }
      &.outline,
      &.empty {
        background: transparent !important;
        color: #fff;
        .dot {
          background: #fff;
          opacity: 0.4;
        }
      }
    }
    &.empty {
      .border {
        opacity: 1;
      }
    }
  }
  .chip {
    background: transparent;
    color: #fff;

    &.outline,
    &.empty {
      color: $text;
      .dot {
        background: $dot;
        opacity: 1;
      }
    }
    &.outline {
      background: $background;
    }
  }
  .dot {
    background: #fff;
    opacity: 0.4;
  }
  .border {
    background: linear-gradient(to bottom, $grad-start, $grad-stop);
    &.top {
      background: linear-gradient(to top, $grad-start, $grad-stop);
    }
    &.left {
      background: linear-gradient(to left, $grad-start, $grad-stop);
    }
    &.right {
      background: linear-gradient(to right, $grad-start, $grad-stop);
    }
  }
  &.empty {
    .border {
      opacity: 0;
    }
  }
}

.success {
  @include chip-format(
    color("success", "dark"),
    color("success"),
    #005c5c,
    // #24b78a,
    // #1791a1,
    color("success")
  );
}
.info {
  @include chip-format(
    color("info", "dark"),
    color("info", "light"),
    color("info", "darkest"),
    color("info")
  );
}
.warning {
  @include chip-format(
    color("warning", "dark"),
    color("warning"),
    #b73908,
    color("warning")
  );
}
.danger {
  @include chip-format(
    color("danger"),
    color("danger"),
    #a80a57,
    color("danger")
  );
}
.default {
  @include chip-format(
    color("text", "dark"),
    color("grey"),
    color("grey", "darkest"),
    color("grey")
  );
}
.primary {
  @include chip-format(
    color("text", "primary"),
    color(primary),
    color("primary", "darkest"),
    color(primary)
  );
}
.secondary {
  @include chip-format(
    color("text", "secondary"),
    color("secondary", "light"),
    color("secondary", "darker"),
    color(secondary)
  );
}

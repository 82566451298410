@import "styles/common";

.contentContainer {
  position: relative;
  background: $background;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: all 600ms;
  pointer-events: auto;
  padding-top: 56px;
  margin-top: -50px;
  &.isDesktop {
    @include desktop {
      width: calc(100vw - 20px);
      transition: width cubic-bezier(0.72, -0.285, 0.275, 1.435) 0.5s;
      padding-right: 30px;
      padding-top: 0;
      margin-right: -80px;
      margin-top: 0;
      &,
      .skeleton {
        border-top-left-radius: 40px;
      }
      &.isExpanded {
        width: calc(100vw - 250px);
      }
    }
  }
  &.background-white {
    background: $background;
  }
  &.background-none {
    background: none;
  }
  &.background-light-grey {
    background-color: #f0f1f3;
  }
  //Top app bar on mobile
  //&::before {
  //  transition: all 600ms;
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  height: 42px;
  //  background-color: color("primary", "lightest");
  //  @include desktop {
  //    display: none;
  //  }
  //  @media print {
  //    display: none;
  //  }
  //}

  .content {
    z-index: 1;
    flex-grow: 1;
    padding: 16px 24px 16px 24px;
    overflow-x: auto;
    &.spacing-none {
      padding: 0;
    }
    &.backgroundImageContent {
      margin-bottom: 120px;
      @include desktop {
        margin-bottom: 160px;
      }
    }
    @include desktop {
      padding: 30px 40px;
      &.spacing {
        &-small {
          padding: 24px 32px;
        }
        &-normal {
          padding: 30px 40px;
        }
        &-large {
          padding: 40px 60px;
        }
        &-extra {
          padding: 60px 72px;
        }
        &-fullscreen {
        }
      }
      &.max {
        margin: 0 auto;
        max-width: 1520px;
        width: 100%;
      }
    }
  }
}

.contentShadow {
  border-top-left-radius: 40px;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 5px 49.75px rgba(1, 1, 1, 0.6);
  z-index: -1;
  pointer-events: none;
  @include desktop {
    top: 0;
    left: 50px;
  }
  @media print {
    display: none;
  }
}

.mobileOpened.contentContainer {
  //position: fixed;
  &,
  &::before {
    border-top-left-radius: 24px;
  }
  .skeleton {
    border-top-left-radius: 24px;
  }
}
.skeleton {
  background-color: mix(color(grey, lightest), #fff, 60);
}

.backgroundImage {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 80vw;
  opacity: 0.6;
  pointer-events: none;
  //max-height: 280px;
  @include desktop {
    width: 40%;
    max-width: 600px;
    right: 10%;
  }
}

@import "styles/common";
.root {
  @include flex--row(flex-start, flex-end);
  & > div:nth-child(1) {
    margin-right: 4px;
  }

  &.reverse {
    & > div > div {
      background-color: #fff !important;
    }
  }
}

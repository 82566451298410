@import "styles/common";

.root {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 10;
  height: 48px;
  border-radius: 40px 0;
  padding: 0 40px;
  font-weight: $fontBold;
  text-transform: uppercase;
  font-size: rem(12.5px);
  letter-spacing: rem(0.9px);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition-property: background-color, border-color, box-shadow, color,
    border-radius, transform, -webkit-box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-in;

  color: color(text, secondary);
  background-color: lighten(color(grey, lightest), 3);
  border-color: color(grey, lighter);
  box-shadow: inset -2px -8px 16px -8px rgba(color(grey, lighter), 0.5);

  &:hover,
  &:focus {
    border-radius: 40px 0;
    color: color(secondary, darker);
    background-color: color(grey, lightest);
    box-shadow: inset -6px -6px 8px -2px rgba(color(grey, light), 0.4);
  }

  @include desktop {
    display: initial;
  }
}

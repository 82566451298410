@import "styles/common";
.loading {
  position: absolute;
  animation: rotating 2s linear infinite;
}
.clock {
  path:first-child {
    opacity: 0;
  }
}

.success {
  font-weight: $fontMedium;
  font-size: 1.1em;
  color: color("success", "dark");
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "styles/common";
@import ".error-types";

.fileErrorContainer {
  background-color: transparentize(color("grey", "lightest"), 0.5);
  padding: 32px 32px 16px 36px;
  border-radius: 6px;
  &.noErrors {
    border: 2px solid transparentize(color("success"), 0.8);
    background-color: transparentize(color("success", "lighter"), 0.7);
    .label {
      color: color("success", "darker");
    }
  }
  &.noRows {
    border: 2px solid transparentize(color("danger"), 0.8);
    background-color: transparentize(color("danger", "lighter"), 0.7);
    .label {
      color: color("danger", "darker");
    }
    .icon {
      color: color("danger", "darker");
    }
  }
}
.fileErrorRow {
  display: flex;
  margin-bottom: 16px;
  font-size: rem(18px);
  flex-direction: column;
  @include tablet {
    flex-direction: row;
  }
  .icon {
    font-size: rem(28px);
    margin-right: 16px;
  }
  .value {
    font-weight: $fontBold;
    min-width: 62px;
  }
  &.noValue {
    color: color("grey", "light");
    .value {
      font-weight: $fontNormal;
    }
    .labelContainer {
      color: color("text", "light");
    }
  }
  .valueContainer {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .labelContainer {
    color: color("text");
    line-height: 1.5;
    margin-left: 8px;
    @include desktop {
      margin-left: 0;
    }
    .label {
      font-weight: $fontMedium;
    }
    .description {
      font-size: rem(13px);
    }
  }
}

.critical,
.success,
.warning,
.error {
  //font-size: rem(18px);
}

.success {
  color: color("success", "dark");
}
.warning {
  color: color("info", "dark");
}
.error {
  color: color("warning", "dark");
}
.critical {
  color: color("danger", "dark");
}
.colStatus {
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 4px;
  }
}
.errors {
  @include errors();
}

@import "styles/common";

@mixin variant-options($color) {
  &.straight,
  &.angled {
    &::before {
      background: linear-gradient(
        to right,
        transparent 0%,
        $color 50%,
        transparent 100%
      );
    }
  }
}

@mixin skeleton-default {
  overflow: hidden;

  @include psuedo--before {
    left: -20%;
    top: 0;
    height: 100%;
    width: 50%;
    opacity: 0.4;
  }
  &.straight:before {
    animation: load 1.5s ease infinite;
  }
  &.angled:before {
    left: -60%;
    top: -50%;
    height: 200%;
    width: 50%;
    transform: rotate(35deg);
    animation: angled-load 1.5s ease infinite;
    @include desktop {
      width: 40%;
      transform: rotate(45deg);
      animation: angled-load-desktop 1.5s ease infinite;
    }
  }
}

@keyframes load {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
@keyframes angled-load {
  from {
    left: -80%;
  }
  to {
    left: 150%;
  }
}
@keyframes angled-load-desktop {
  from {
    left: -60%;
  }
  to {
    left: 130%;
  }
}

@import "styles/common";
@import "skeleton_common";

.textWrap {
  position: relative;
  width: 100%;
  @include flex(center, center);
  &.icon {
    font-size: rem(22px);
    line-height: 1;
    .skeleton {
      width: 1em;
      height: 1em;
      &:before {
        width: 100%;
        animation-name: load-icon;
      }
    }
  }
  &.text {
    .skeleton {
      height: 1em;
    }
  }
}

.skeleton {
  position: absolute;
  width: 100%;
  height: 100%;
  @include skeleton-default;
  border-radius: 1em;

  &.base {
    background-color: color(grey, lighter);
    @include variant-options(color(grey, lightest));
  }
  &.dark {
    background-color: color(grey, light);
    @include variant-options(color(grey, lighter));
  }
  &.light {
    background-color: color(grey, lightest);
    @include variant-options(#fff);
  }
  &.inverse {
    @include variant-options(#fff);
    opacity: 0.8;
  }
}

@keyframes load-icon {
  from {
    left: -100%;
  }
  to {
    left: 150%;
  }
}

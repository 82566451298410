@import "styles/common";
.root {
  //width: 100%;
  //height: 100vh;
}

.bird1 {
  animation: float 4s ease-in-out 100ms infinite alternate;
}

.bird2 {
  animation: float 3s ease-in-out infinite alternate-reverse;
}

.bird3 {
  animation: float 3s ease-in-out infinite alternate;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(-12px);
  }
}

@import "styles/common";

//$cardBg: #eceef3;
$cardBg: #f1f4fc;
$cardBgHover: #ecf5ff;

.wrapper {
  display: flex;
  flex-wrap: wrap;
  @include desktop {
    @include column-layout;
  }
}

.root {
  position: relative;
  padding: 10px;
  flex: 0 1 100%;

  @include desktop {
  }
  &:hover,
  &:focus,
  &:active {
    .card {
      background-color: $cardBgHover;
      //box-shadow: -12px -12px 24px #ffffff, 12px 12px 24px #d2d0d9;
      box-shadow: 8px 8px 12px #c9cbcf, -8px -8px 12px #ffffff;
      .title {
        color: color("primary", "dark");
      }
      .background:after {
        opacity: 0;
      }
      .backgroundIllustration {
        right: -16px;
        @include huge {
          right: -8px;
        }
      }
    }
  }
}

.card {
  position: relative;
  min-height: 300px;
  padding: 18px 22px;
  display: flex;
  flex-direction: column;
  @include desktop {
    min-height: 240px;
  }

  //background-color: #f0f2f5;
  //background-color: #F5F7FB;
  background-color: $cardBg;
  //box-shadow: -6px -6px 12px #ffffff, 6px 6px 12px #dfe1e4;
  //box-shadow: -9px -9px 18px #ffffff, 9px 9px 18px #dfe1e4;
  //box-shadow: -3px -3px 9px #ffffff, 4px 4px 8px #dfe1e4;
  box-shadow: 5px 5px 10px #dddee0, -5px -5px 10px #ffffff;
  //box-shadow: -3px -3px 8px #ffffff, 4px 4px 6px #dfdfe9;
  border-radius: 18px;
  transition: 400ms ease-in-out;
  transition-property: background-color, box-shadow;
  overflow: hidden;
}

.title {
  z-index: 1;
  position: relative;
  color: color("primary");
  font-weight: $fontBold;
  font-size: rem(32px);
  padding-bottom: rem(12px);
  max-width: 80%;
}
.description {
  z-index: 1;
  position: relative;
  flex-grow: 1;
  color: color("grey", "darker");
  font-size: rem(18px);
  max-width: 80%;
}
.button {
  z-index: 1;
  position: relative;
  align-self: flex-start;
  padding: rem(12px) rem(32px);
  border-radius: rem(10px);
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.background {
  @include absolute-cover();
  pointer-events: none;
  &.fadeOverlay:after {
    content: "";
    transition: opacity 400ms ease-in-out;
    opacity: 1;
    @include absolute-cover();
    background-image: linear-gradient(
      to top,
      transparent 60%,
      transparentize($cardBg, 0.55) 65%
    );
  }
}

.backgroundImage {
  position: absolute;
  bottom: -12px;
  right: -24px;
  height: auto;
  width: 480px;
  min-width: 260px;
  //z-index: -1;
  //@include absolute-cover;
}
.backgroundIllustration {
  position: absolute;
  bottom: -6px;
  right: -24px;
  height: 70%;
  width: auto;
  //min-width: 260px;
  //transition: right 400ms ease-in-out;
  transition: right 400ms cubic-bezier(0.4, 0, 0.2, 1);
  @include desktop {
    height: 85%;
  }
  @include huge {
    height: 105%;
  }
}

.skeleton {
  .title {
    max-width: 260px;
  }
  .description {
    max-width: 420px;
  }
  .swipe {
    z-index: 1;
  }
}

@import "styles/common";

.pageTitle {
  color: color(primary, darker);
}

.pageBlurb {
  margin-bottom: 32px;
}
.clientTitle {
  color: color(text, light);
  margin: 0 0 -6px 0;
}
.clientLogo {
  width: 450px;
  margin-bottom: 12px;
  max-width: 70%;
  @include desktop {
  }
}
//.ribbon {
//  position: absolute;
//  z-index: -1;
//  top: 100px;
//  right: -50px;
//  transform: rotateX(180deg) rotateY(180deg) rotate(45deg);
//  width: 600px;
//  @include desktop{
//    top: 10px;
//    right: -20px;
//    width: 420px;
//    transform: rotateX(180deg) rotateY(180deg) rotate(20deg);
//  }
//  @include huge{
//    right: -200px;
//    top: 100px;
//    width: 600px;
//    transform: rotateX(180deg) rotateY(180deg) rotate(45deg);
//  }
//  @media (min-width: 1800px) {
//    right: -50px;
//  }
//}

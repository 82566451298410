@import "styles/common";

.pageHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  text-align: center;
  @include desktop {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }

  h1,
  h2 {
    margin: 0;
  }
  h2 {
    margin: -6px 0 12px 0;
    font-size: rem(20px);
    font-weight: $fontBold;
    color: color("text", "light");
  }
}

.flex {
  display: flex;
  flex-direction: column;

  @include desktop {
    align-items: flex-end;
  }
}
.filters {
  margin: 0;
  @include desktop {
    & > div {
      margin-bottom: 0;
    }
  }
}
.headerContainer {
  margin-bottom: rem(18px);
}

@import "styles/common";

.root {
  z-index: 0;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  @include desktop {
    flex-direction: row-reverse;
  }
  &.mobileOpened {
    overflow: hidden;
  }
}

.contentWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
  @include desktop {
    z-index: auto;
    position: relative;
    pointer-events: none;
    padding-left: 50px;
    margin-left: -50px;
    overflow-x: hidden;
  }
  .contentOverlay {
    @include absolute-cover;
    width: 100%;
    padding: 0;
    pointer-events: auto;
    cursor: pointer;
    z-index: 1000;
    @include desktop {
      display: none;
    }
  }
}

.fullscreenLayout {
  .contentWrapper {
    z-index: 1;
    .contentOverlay {
      @include desktop {
        display: inherit;
      }
    }
  }
}

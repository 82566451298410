@use "sass:math";
@import "styles/common";

$hamburger-padding-x: 8px !default;
$hamburger-padding-y: 12px !default;
$hamburger-layer-width: 20px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: color(grey) !default;
$hamburger-active-layer-color: color(secondary, dark) !default;

$hamburger-layer-border-radius: 6px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

.root {
  z-index: 10;
  pointer-events: auto;
  position: relative;
  top: 2px;
  left: 8px;
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  height: 48px;
  overflow: visible;

  @include desktop {
    top: 20px;
    left: 18px;
  }

  //&:hover {
  //  @if $hamburger-hover-use-filter == true {
  //    filter: $hamburger-hover-filter;
  //  } @else {
  //    opacity: $hamburger-hover-opacity;
  //  }
  //}

  &.open {
    //&:hover {
    //  @if $hamburger-hover-use-filter == true {
    //    filter: $hamburger-active-hover-filter;
    //  } @else {
    //    opacity: $hamburger-active-hover-opacity;
    //  }
    //}

    .inner,
    .inner::before,
    .inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.inner {
  display: block;
  //top: 50%;
  margin-top: math.div($hamburger-layer-height, -2);
  top: math.div($hamburger-layer-height, 2);
  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}
@include desktop {
  .inner {
    &,
    &::before,
    &::after {
      width: $hamburger-layer-width * 0.8;
      height: 2px;
    }
    &::before {
      top: ($hamburger-layer-spacing + 2px) * -1;
    }
    &::after {
      bottom: ($hamburger-layer-spacing + 2px) * -1;
    }
  }
}

.arrowalt {
  .inner {
    &::before {
      transition: top 0.1s 0.1s ease,
        transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &::after {
      transition: bottom 0.1s 0.1s ease,
        transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  &.open {
    .inner {
      &::before {
        top: 0;
        transform: translate3d(
            $hamburger-layer-width * -0.2,
            $hamburger-layer-width * -0.25,
            0
          )
          rotate(-45deg) scale(0.7, 1);
        transition: top 0.1s ease,
          transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }

      &::after {
        bottom: 0;
        transform: translate3d(
            $hamburger-layer-width * -0.2,
            $hamburger-layer-width * 0.25,
            0
          )
          rotate(45deg) scale(0.7, 1);
        transition: bottom 0.1s ease,
          transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
    }
  }
}

.squeeze {
  .inner {
    top: $hamburger-layer-height * 3;
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.open {
    .inner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
.elastic {
  .inner {
    top: math.div($hamburger-layer-height, 2);
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &::before {
      top: $hamburger-layer-height + $hamburger-layer-spacing;
      transition: opacity 0.125s 0.275s ease;
    }

    &::after {
      top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }

  &.open {
    .inner {
      $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

      transform: translate3d(0, $y-offset, 0) rotate(135deg);
      transition-delay: 0.075s;

      &::before {
        transition-delay: 0s;
        opacity: 0;
      }

      &::after {
        transform: translate3d(0, $y-offset * -2, 0) rotate(-270deg);
        transition-delay: 0.075s;
      }
    }
  }
}

.collapse {
  .inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::after {
      top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
    }

    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.open {
    .inner {
      transform: translate3d(
          0,
          ($hamburger-layer-spacing + $hamburger-layer-height) * -1,
          0
        )
        rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s 0.22s linear;
      }

      &::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.spring {
  .inner {
    top: math.div($hamburger-layer-height, 2);
    transition: background-color 0s 0.13s linear;

    &::before {
      top: $hamburger-layer-height + $hamburger-layer-spacing;
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &::after {
      top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.open {
    .inner {
      transition-delay: 0.22s;
      background-color: transparent !important;

      &::before {
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(
            0,
            $hamburger-layer-spacing + $hamburger-layer-height,
            0
          )
          rotate(45deg);
      }

      &::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(
            0,
            $hamburger-layer-spacing + $hamburger-layer-height,
            0
          )
          rotate(-45deg);
      }
    }
  }
}

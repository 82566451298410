@import "styles/common";

.table {
  width: auto !important; /*override the width below*/
  width: 100%;
  max-width: 100vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  @include subtle_horizontal_scrollbar();
}

.column {
  overflow: hidden;
  text-overflow: ellipsis;
  &.iconCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0 !important;
    //width: 50px;
  }
}
.header,
.row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  padding: 0 12px;
  width: 100%;
  margin: auto;
  @include desktop {
    padding: 0 24px;
  }
  @include huge {
    width: $containerWidth;
    max-width: $containerMax + 48px;
  }
}
.header {
  .column {
    text-align: left;
    font-weight: $fontLight;
    color: color("text", "light");
  }
}
.body {
  position: relative;
  a:nth-child(odd) > .rowWrapper,
  > .rowWrapper:nth-child(odd) {
    background: color("grey", "lightest");
  }
  &,
  a {
    .rowWrapper:hover {
      background: color("grey", "lighter");
      color: color("text", "dark");
    }
  }
}

.headerWrapper,
.rowWrapper {
  min-width: fit-content;
}
.rowWrapper {
  padding: 12px 0;
  color: color("text");
  transition: background-color 200ms ease-in-out;
  border-radius: 4px;
  position: relative;
  @include desktop {
  }
}
.row {
  font-size: rem(13px);
  .column {
  }
}

.placeholder {
  background-color: color(grey, lightest);
  color: rgba(color(text), 0.7);
  font-style: italic;
  padding: 0 12px;
  position: relative;
  transition: height, background-color 200ms ease-in-out;
  @include flex--column();
  .placeholderLabel {
    padding: 0 12px;
    width: 100%;
    text-align: center;
    margin: auto;
    @include desktop {
      text-align: left;
      padding: 0 44px;
    }
    @include huge {
      width: $containerWidth;
      max-width: $containerMax + 24px;
    }
  }
}

.loading {
  .column:not(.iconCol) {
    margin-right: rem(16px);
  }
}

.body {
  a:nth-child(odd) > .rowWrapper,
  > .rowWrapper:nth-child(odd) {
    .skeleton {
      background-color: darken(color(grey, lighter), 5%) !important;
    }
  }
}

@import "styles/common";

.root {
  //background-color: #edeff3;
  //background-color: #f0f1f3;
  & > main {
    @include flex--column();
  }
}

.tabContent {
  position: relative;
  @include huge {
    //width: $containerWidth;
    max-width: $containerMax;
    //max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    min-width: initial;
  }
  .tab {
    font-size: rem(14px);
    //padding-bottom: 0 !important;
  }
}

.gauge {
  margin: 10px auto 2px auto;
}

.actions {
  padding-top: 24px;
  background-color: #fff;
}

@import "styles/common";

.inputFeedback {
  color: color("text", "danger");
  font-size: 0.8em;
  padding-left: 10pt;
  margin-bottom: 1.4em;
  letter-spacing: 0.03em;
  text-align: left;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
}
.icon {
  color: color("danger");
  font-size: 1.2em;
  margin-right: 4px;
}

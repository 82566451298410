@import "../utils";
@import "../config/typography";

// Default buttons and links
a {
  font: $fontNormal $fontSize $fontBody;
  color: color(secondary, dark);
}

button {
  font: $fontNormal $fontSize $fontBody;
}

.link,
button.link {
  color: color(secondary);
  text-decoration: none;
  transition: 0.7s;
  @include desktop {
    &:hover,
    &:focus {
      text-decoration: underline;
      color: color(secondary, dark);
    }
  }
}

@import "styles/common";

.menu {
  width: fit-content;
  min-width: fit-content;
  z-index: 999999999;
  border: 1px solid color("primary", "lighter"); //rgb(215, 217, 226);
  border-radius: 8px;
  font-size: rem(14px);
  background: #fff;
  box-shadow: rgb(215, 217, 226) 0 2px 4px 0;
  transition: opacity 0.2s ease-in-out 0s;
  overflow: hidden;
  pointer-events: none;

  ul {
    padding: 8px 0;
    margin: 0;
  }
  &.open {
    pointer-events: auto;
  }
}

.menuTrigger {
  width: 100%;
  @include absolute-cover();
}

@import "styles/common";
@import "components/common/ui/charts/chart-palettes";

.root {
  padding: 18px 0;
}
.divider {
  width: 100%;
  position: relative;
  color: color(grey);
  opacity: 0.7;
  @include flex--column(center);
  @include psuedo--before {
    left: 24px;
    right: calc(50% + 24px);
    top: 50%;
    border-bottom: 1px solid color(grey, lighter);
  }
  @include psuedo--after {
    right: 24px;
    left: calc(50% + 24px);
    top: 50%;
    border-bottom: 1px solid color(grey, lighter);
  }
}

.table {
  //background-color: #ffffff;
  padding: 0;
  margin: 0;
  .header {
    color: color(grey, light);
    font-style: italic;
    font-size: 0.9em;

    position: relative;
    svg {
      margin: 6px 12px 0 12px;
    }
    span {
      color: color(text, light);
      flex-grow: 1;
      width: 50%;
      border-bottom: 1px solid color(grey, lighter);
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
  .header,
  .row {
    @include flex--row(flex-start, space-between) {
      padding: 3px 12px;
    }
  }
  .row {
    font-weight: $fontMedium;
    margin-bottom: 2px;
    & > span:nth-child(2) {
      font-weight: $fontBold;
      margin-left: 12px;
    }

    &:hover {
      background-color: color(grey, lighter);
    }
    &:nth-child(odd) {
      background-color: #eaeaea;
      border-radius: 3px;
      //color: darken(color(text), 5);
      &:hover {
        background-color: color(grey, lighter);
      }
    }
    .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 12px;
      background: currentColor;
      margin: 0 8px 2px 0;
    }
  }
}
.dot {
  color: #cccccc;
}

.sunsetTheme {
  @include chart-theme($chart-pallete-sunset-6, "dotIndex");
}

.oceanTheme {
  @include chart-theme($chart-pallete-ocean-6, "dotIndex");
}

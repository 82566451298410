@import "styles/common";

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 50px;
  max-width: 100%;
}

.title {
  color: color("danger");
  line-height: 1.1;
  //font-size: rem(32px);
  //padding-bottom: 30px;
}
.description {
  padding-bottom: 50px;
}

// Default app styles used on all pages

@import "normalise";
@import "reset";
@import "root";
@import "typography";
@import "buttons-and-links";
@import "unsupported-browser";
@import "reaviz-overrides";

:root {
  @include reaviz-overrides;
}

@import "styles/common";

$center: color(secondary);

.root {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  font-size: 19px;
  width: 1em;
  height: 1em;
}

.bg,
.fg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  max-width: 162px;
  height: 162px;
  margin-top: -81px;
  margin-left: -80px;
  opacity: 0.8;
}
.fg {
  animation: fg-spin 5000ms infinite linear;
}
@keyframes fg-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

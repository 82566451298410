@import "styles/common";

.sankeyWrapper {
  @include absolute-cover;
  & > div {
    @include absolute-cover;
    &:nth-child(2) {
      left: 20px;
      right: 20px;
      width: calc(100% - 40px) !important;
    }
  }
  .noData {
    text-align: center;
    top: 40%;
    font-weight: $fontNormal;
    font-size: rem(14px);
    color: color(text, light);
    font-style: italic;
  }
  .sankey {
    text {
      fill: color(grey, darkest);
      paint-order: stroke;
      stroke: #fff;
      stroke-opacity: 0.5;
      stroke-width: 3px;
      opacity: 1;
      stroke-linecap: round;
      stroke-linejoin: round;
      font-size: rem(13px);
      font-weight: $fontMedium;
    }
    .sankeyLink {
      mix-blend-mode: inherit;
    }
  }
}

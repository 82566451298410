@import "styles/common";

.root {
  flex-shrink: 0;
  height: 106px;
  background: lighten(color("primary", "lightest"), 19%);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-family: $fontBody;
  font-size: 13px;
  pointer-events: auto;

  @media print {
    display: none;
  }

  &.absolute {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .links {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      cursor: pointer;
    }

    .link {
      color: color("text", "primary");
      text-decoration: none;

      &:not(:first-child) {
        margin-left: 8px;

        &::before {
          content: "|";
          margin-right: 8px;
        }
      }
    }
  }

  .copy {
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(color("text", "primary"), 0.6);
  }
}

@import "styles/common";

.sectionContent {
  @include flex--row(flex-start, flex-start, wrap);
}
.indicatorTitle {
  margin: 0 0 16px 0;
  font-weight: $fontMedium;
  padding-bottom: 8px;
  border-bottom: 1px solid color(grey, lightest);
  color: color(gigas, darkest);
}
.chartWrapper {
  height: 210px;
  @include flex(center);
  flex-grow: 1;
  position: relative;
}

.gauge {
  margin-bottom: 11px;
}

@import "styles/common";

.root {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 rem(6px);
  pointer-events: auto;
  @media print {
    display: none;
  }
  @include desktop {
    position: fixed;
    top: 0;
    right: 0;
    padding: rem(6px) rem(12px);
    background-color: color(primary, lightest);
    height: rem(42px);
    border-radius: rem(40px) 0 0 rem(40px);
  }
}

@import "styles/common";

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 36px;
  width: 100%;
  text-align: initial;
  &.bottomBorder {
    border-bottom: 1px solid color("grey", "lighter");
    margin-bottom: 12px;
  }
  //font-size: 30px;
}

.headerContent {
  flex-grow: 1;
}

.expandArrow {
  margin: auto 12px;
  font-size: 1.3em;
  color: color("text", "light");
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  &.expanded {
    transform: rotate(90deg);
  }
}
.content {
  flex-grow: 1;
  overflow-y: auto;
  @include subtle_scrollbar;
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: color("grey", "lightest");
  }
}

@import "styles/common";

.orgList {
  .orgRowHeader:nth-child(4n + 1) {
    background-color: color(grey, lightest);
  }
}
.orgRowHeader {
  padding: 12px 16px;
  border-radius: 2px;

  & > div {
    @include flex(center, space-between);
  }
}

.orgRow {
}
.schemaContent {
  border-top: 1px solid color(grey, lighter);
  padding: 4px 6px 24px 6px;
  .schemaRow:nth-child(2n) {
    background-color: rgba(color(grey, lightest), 0.5);
    border-radius: 4px;
    .noUploads {
      background-color: rgba(color(grey, lighter), 0.4);
    }
  }
}
.schemaRow {
  padding: 0 12px;
  @include flex(center, space-between);
  b {
    flex-grow: 1;
  }
}

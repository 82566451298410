@import "styles/common";
.root {
  //width: 220px;
}
.outer {
  fill: #5ac8ae;
}
.inner {
  fill: #d7fdfa;
}

.light {
  .outer {
    fill: #5ac8ae;
  }
  .inner {
    fill: #d7fdfa;
  }
}
.dark {
  .outer {
    fill: color(primary, light);
  }
  .inner {
    fill: color(secondary, dark);
  }
}

@import "styles/common";

.column {
  background-color: rgba(color("grey", "lightest"), 0.5);
  border-radius: rem(6px);
  margin: rem(12px) 0 rem(6px) 0;
  padding: rem(8px);
  @include desktop {
    border-radius: rem(12px);
    margin: rem(14px) 0 rem(10px) 0;
    padding: rem(8px);
  }
}

.header {
  font-size: rem(20px);
  h4 {
    margin: 0;
    font-size: rem(17px);
    svg {
      position: absolute;
      font-size: rem(16px);
      color: color("danger", "dark");
    }
    display: flex;
    align-items: center;
    .name {
      margin-left: rem(20px);
      flex-grow: 1;
    }
    .req {
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: $fontBold;
      color: rgba(color("danger", "darker"), 0.4);
    }
  }
}
.content {
  margin-top: rem(4px);
  padding: rem(2px) rem(20px);
  background-color: #fff;
  border-radius: rem(4px);
  @include desktop {
    border-radius: rem(8px);
    margin: rem(6px) rem(18px) rem(12px) rem(18px);
    padding: rem(12px) rem(20px);
  }
  i {
    white-space: pre-line;
  }
  .row {
    .attribute {
      padding-right: rem(6px);
    }
    display: flex;
    align-items: center;
    margin: rem(12px) 0;
  }
}
.attribute {
  color: color("text", "light");
  font-weight: $fontMedium;
}

.allowedValues {
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin: 0 2px 2px 0;
  }
}
.highlight {
  font-weight: $fontBold;
  color: color(gigas, dark);
}

.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6px 0 -6px 0;
  @include desktop {
    flex-direction: row;
    justify-content: space-between;
  }
  .expandAll {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    @include desktop {
      align-self: inherit;
    }
    svg {
      font-size: rem(16px);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
      margin: -2px 0 0 6px;
      flex-shrink: 0;
    }
    &.expanded {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

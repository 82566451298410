@import "styles/common";

.root {
  &.darker {
    color: color(primary, darker);
  }
  margin-top: 20px;
  @include desktop {
    margin-top: 0;
  }
  @each $theme in $themes-variants {
    &.theme-#{$theme} {
      color: color($theme);

      &.darker {
        color: color($theme, darker);
      }
    }
  }
}

$chart-pallete-ocean-6: (#4139a8, #424bb4, #3f6bc0, #3a8bc3, #30acbd, #1ac7ac);
$chart-pallete-sunset-6: (#6034a9, #923390, #c14e83, #e27379, #f79c70, #ffc969);

@mixin chart-theme($refcolors, $class) {
  @for $i from 1 through length($refcolors) {
    $c: nth($refcolors, $i);
    .#{$class}-#{($i - 1)} {
      color: $c;
    }
  }
}

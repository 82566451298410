@import "styles/common";

.root {
  //background-color: #edeff3;
  //background-color: #f0f1f3;
  & > main {
    @include flex--column();
  }
}

.summaryCard,
.card {
  position: relative;
  @include flex--column(center, center);
  flex-wrap: wrap;
  overflow: hidden;
  @include desktop {
    @include flex--row(center, space-around);
  }
  .ribbon {
    position: absolute;
    height: 160px;
    width: 100%;
    left: 0;
    right: 0;
  }
}
.card {
  .ribbon {
    display: none;
  }
  @include desktop {
    .ribbon {
      height: 130px;
      display: inherit;
      bottom: 40px;
      transform: rotate(183deg);
    }
    .headlineImg {
      margin-right: 42px;
    }
    .headlineContainer {
      & > div {
        margin-top: 42px;
      }
    }
  }
}

.summaryCard {
  padding-top: rem(24px);
  .period {
    position: absolute;
    right: 12px;
    top: 8px;
    font-weight: $fontMedium;
    color: color(text, light);
    text-transform: uppercase;
    font-size: rem(16px);
    pointer-events: none;
  }
  .headlineContainer {
    line-height: initial;
    padding: 22px;
    text-transform: initial;
    @include flex--column(center, center);
    .headlineImg {
      width: 200px;
    }
  }
  .ribbon {
    top: 116px;
    transform: none;
  }
  @include psuedo--before() {
    left: 0;
    right: 0;
    top: 0;
    height: 168px;
    background: color(grey, lightest);
  }
}
.headlineContainer {
  position: relative;
  z-index: 2;
  text-align: center;
  @include flex--column(center);
  @include desktop {
    @include flex--row(flex-start, center);
  }

  .headlineImg {
    position: relative;
    width: 220px;
  }
}

.headline {
  & > div {
    text-align: center;
    &:nth-child(1) {
      flex-grow: 1;
      //text-align: right;
    }
    &:nth-child(2) {
      //font-size: rem(22px);
      //text-align: right;
    }
  }
}

.row {
  @include flex--column(center, center);
  margin: 24px 0;
  .rowCard {
    margin: 12px 0;
    .title {
      margin: 0;
      font-weight: $fontMedium;
      color: color(text);
    }
    p {
      margin-top: 0;
      padding-top: 0;
      color: color(text, light);
    }
  }
  .chartWrapper {
    width: 100%;
    height: 250px;
  }
  @include desktop {
    @include flex--row(stretch, space-around);
    .rowCard {
      margin: 0 12px;
    }
  }
}
.desktopBreak {
  display: none;
  @include desktop {
    display: inherit;
  }
}
.measureHeadline {
  width: 100%;
  justify-content: center;
  color: color(text);
}
.spacer {
  border-color: transparent;
  border-top: 1px solid color(grey, lighter);
  margin: 16px 18px;
}

@import "styles/common";

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: color("grey", "dark");
  outline: none;
  transition: border, background-color 0.24s ease-in-out;
  cursor: pointer;
  text-align: center;
  &:hover,
  &:focus {
    background-color: color("grey", "lightest");
    border-style: solid;
    border-color: color("grey", "lighter");
  }
}

.input {
  display: none;
}

.fileList {
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 12px;
}

.fileButton {
  display: inline-flex;
  padding: 0.7rem 0;
  line-height: 1rem;
  font-size: 0.975rem;
  border: none;
  color: color("text");
  font-weight: $fontBold;
  white-space: nowrap;
  cursor: pointer;
  background-color: color("grey", "lightest");
  border-radius: 5px;
  margin: 0.2rem;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  overflow: hidden;
  align-items: center;
  min-width: 200px;
  transition: box-shadow, background-color 200ms ease-in-out;
  &:hover,
  &:focus {
    background-color: lighten(color("grey", "lighter"), 4);
    box-shadow: $lightShadow;
    border: none;
    outline: 0;
  }
  span {
    flex-grow: 1;
  }
  svg {
    font-size: rem(18px);
    color: color("grey", "light");
    margin: 0 16px;
  }
}

@import "../config/responsive";

@mixin phone {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx),
  only screen and (max-width: #{$tabletWidth - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tabletWidth}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktopWidth}) {
    @content;
  }
}

@mixin huge {
  @media (min-width: #{$hugeWidth}) {
    @content;
  }
}
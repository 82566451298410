@import "styles/common";

.root {
  //@include flex--row(start, space-between);
  @include flex--row(flex-start, flex-start);
  //text-align: right;
  font-weight: $fontMedium;
  font-size: rem(16px);

  div {
    margin-right: 24px;
  }
  .label {
    color: color(gigas, dark);
    padding-bottom: 2px;
  }
  .bigNum {
    font-weight: $fontBold;
    font-size: rem(42px);
    line-height: 1;
    color: color(gigas);
  }
  .smallNumber {
    font-size: rem(28px);
    line-height: 1;
    color: color(text, light);
  }
}
.bigNum,
.smallNumber {
  min-height: 44px;
  @include flex--row();
  span {
    font-weight: $fontNormal;
    font-size: rem(18px);
    color: color(text, light);
    font-style: italic;
  }
}
.root.arrow {
  padding-bottom: 20px;
  .bigNum,
  .smallNumber {
    position: relative;
    @include psuedo--before {
      left: 0;
      width: 0;
      height: 0;
      display: inline-block;
    }
    &.positive {
      &:before {
        border-bottom-color: currentColor;
      }
    }
    &.negative {
      &:before {
        border-top-color: currentColor;
      }
    }
    span {
      margin: 0 24px 0 -24px;
    }
  }
  .bigNum {
    padding-left: 24px;
    &:before {
      border: 10px solid transparent;
    }
    &.positive {
      color: color(success);
      &:before {
        top: rem(10px);
      }
    }
    &.negative {
      color: color(danger);
      &:before {
        top: rem(18px);
      }
    }
  }
  .smallNumber {
    padding-left: 16px;
    &:before {
      border: 7px solid transparent;
    }
    &.positive {
      &:before {
        top: rem(6px);
      }
    }
    &.negative {
      &:before {
        top: rem(12px);
      }
    }
  }
}

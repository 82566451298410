@import "styles/common";

.root {
  position: sticky;
  top: 0;
  height: 50px;
  width: 100vw;
  z-index: 9;
  pointer-events: all;
  @include flex(center, space-between);
  @include desktop {
    display: none;
  }
  @include no-print;

  &.open {
    &.isTop {
      &:before {
        border-top-left-radius: 24px;
      }
    }
  }
  &.isScrolled {
    &:before {
      box-shadow: $mediumShadow;
      background: rgba(#fff, 0.4);
      backdrop-filter: blur(7px);
      @supports not (
        (-webkit-backdrop-filter: none) or (backdrop-filter: none)
      ) {
        background-color: rgba(#fff, 0.95);
      }
    }
  }

  @include psuedo--before {
    @include absolute-cover;
    background: transparent;
    transition: box-shadow 200ms ease 0s, background-color 200ms ease 0s,
      border-radius 600ms;
  }
}
.logo {
  position: relative;
  font-size: 26px;
}
.brand {
  position: relative;

  width: 150px;
  max-width: calc(100vw - 150px);
  transition: opacity 300ms ease-in-out 200ms;
  &.fade {
    opacity: 0;
  }
}

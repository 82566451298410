@import "styles/common";

.container {
  width: $containerWidth;
  max-width: $containerMax;
  margin: 0 auto;
  position: relative;
  //min-height: 100vh;
  &.spacing-none {
    padding: 0;
  }
  @include desktop {
    &.spacing {
      &-small {
        padding: 24px 32px;
      }
      &-normal {
        padding: 30px 40px;
      }
      &-large {
        padding: 40px 60px;
      }
      &-extra {
        padding: 60px 72px;
      }
    }
    &.spacingTopOnly {
      padding-right: 0 !important;
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.row {
  display: block;
  width: 100%;
  clear: both;
}

.half {
  width: 50%;
  overflow: auto;
  float: left;
}

.text-container {
  max-width: $textContainer;

  &--center {
    margin-left: auto;
    margin-right: auto;
  }
}

/* ---------------------
  Custom resets
--------------------- */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: "liga" on;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

*,
*:before,
*:after {
  //margin: 0;
  //padding: 0;
  box-sizing: inherit;
}

a {
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    button {
      text-decoration: none;
      display: inline-block;
      color: inherit;
    }
  }
  button {
    display: inline-block;
  }
}

button {
  outline: 0;
  border: 0;
  background-color: transparent;
}

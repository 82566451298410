@import "styles/common";

.root {
  padding: 12px 0;
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include desktop {
    flex-direction: row;
    justify-content: space-between;
  }
}

.table {
  .danger {
    background: color("danger", "lightest");
    & > div {
      color: color("danger", "darkest");
    }
  }

  .warning {
    background: color("warning", "lightest");
    & > div {
      color: color("warning", "darkest");
    }
  }
  a:hover,
  a:focus {
    .danger {
      background: color("danger", "lighter");
    }
    .warning {
      background: color("warning", "lighter");
    }
  }

  .clientColumn {
    font-weight: $fontMedium;
    font-size: 1.1em;
  }
}

.iconCol {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0 !important;
  .danger {
    color: color(danger);
    background: none !important;
  }
}
.statusContainer {
  @include flex(center);
  min-height: 27px;

  .status {
    font-weight: $fontNormal !important;
  }
}

.icon {
  margin: 0 2px;
  cursor: pointer;
  color: color(info, darker);
  height: 27px;
  width: 27px;
  border-radius: 14px;
  padding: 3px;
  transition: color 200ms ease-in-out;
  background-color: color(info, lightest);
  @include flex(center, center);
  &:hover {
    color: color(info);
  }
  svg {
    //margin-bottom: -6px;
  }
}
.resend {
  color: color(warning, darker);
  background-color: color(warning, lightest);
  &:hover {
    background-color: color(warning, lightest);
    color: color(warning, darkest);
  }
}

.deletedAccordian {
  width: 88%;
  max-width: 1400px;
  margin: 24px auto 12px auto;
  position: relative;
  border-bottom: 1px solid color(danger, lighter);
  h4 {
    color: color(text, danger);
    font-weight: $fontBold;
  }
}

@import "styles/common";
.root {
  overflow: hidden;
}

.right {
  position: absolute;
  top: 40px;
  right: -5vw;
  width: 48%;
  height: auto;
  max-width: 400px;
  @include desktop {
    width: 18vw;
  }
}
.bottomLeft {
  position: absolute;
  left: 3vw;
  bottom: 0;
  width: 40%;
  height: auto;
  transform: scale(-1, 1);
  max-width: 380px;
  @include desktop {
    width: 16vw;
  }
}
.topLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: 36%;
  height: auto;
  max-width: 350px;
  @include desktop {
    width: 14vw;
  }
}

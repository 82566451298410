@import "styles/common";
$radius: 30px;

.button {
  position: relative;
  display: inline-block;
  padding: rem(10px) rem(16px);
  line-height: rem(14px);
  font-size: rem(12.5px);
  text-transform: uppercase;
  border: 1.1px solid color(primary);
  color: color(primary);
  font-weight: $fontBold;
  letter-spacing: 0.06em;
  //transition: 0.7s;
  white-space: nowrap;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  margin: rem(3px);
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition-property: background-color, border-color, box-shadow, color,
    transform, -webkit-box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-in;

  &:hover,
  &:focus {
    outline: 0;
  }

  @include desktop {
    &:hover {
      border-color: color("primary", "darkest");
      color: color("primary", "darkest");
    }
    &:not(.noHover) {
      &:hover,
      &:focus,
      &:focus-within {
        transform: scale(1.04);
        &:active {
          transform: scale(0.996);
        }
      }
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
  // Utility classes
  &Fixed {
    width: 160px;
  }
  &Fixed2 {
    width: 220px;
  }
}

.rounded {
  border-radius: $radius;
}

.submitting:disabled {
  opacity: 0.8;
  .submittingLoader {
    pointer-events: none;
    @include absolute-cover();
    background-color: transparentize(#fff, 0.5);
    font-size: 16px;
  }
}
.loading:disabled {
  opacity: 1;
  border-color: color(grey, lighter) !important;
  .skeleton {
    @include absolute-cover;
    border-radius: 0;
  }
}

.iconButton {
  padding: rem(4px) rem(16px) rem(4px) rem(4px);
  .content {
    display: flex;
    align-items: center;
  }
  .icon {
    border-radius: 50%;
    //padding: 6px;
    height: 35px;
    width: 35px;
    margin-right: rem(8px);
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}

@mixin format-button(
  $text,
  $outlined-text,
  $bg,
  $hover,
  $avatarBg,
  $lightFill
) {
  background-color: $bg;
  border-color: $bg;
  color: $text;
  &:hover,
  &:focus,
  &:focus-within {
    color: $text;
  }
  @include desktop {
    &:hover,
    &:focus,
    &:focus-within {
      background-color: $hover;
      border-color: $hover;
      color: $text;
      box-shadow: 0 2px 6px rgba(color("grey", "darker"), 0.2);
      &:active {
        background-color: darken($bg, 1%);
        box-shadow: inset 0 4px 8px rgba(color("grey", "dark"), 0.1);
      }
    }
  }
  &.submitting:disabled .submittingLoader {
    background-color: transparentize($bg, 0.4);
  }
  &.lightFilled {
    color: $outlined-text;
    background-color: $lightFill;
    border: none;
  }

  &.outline,
  &.empty {
    background-color: transparent;
    color: $outlined-text;
    &.submitting:disabled {
      color: rgba($outlined-text, 0.4);
      .submittingLoader {
        background-color: rgba($hover, 0.2);
        color: $bg;
      }
    }
    @include desktop {
      &:hover,
      &:focus {
        background-color: rgba($bg, 0.1);
        &:active {
          background-color: rgba($bg, 0.4);
        }
      }
    }
  }
  &.empty {
    border-color: transparent;
    @include desktop {
      &:hover,
      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
    &.iconButton .icon.icon-empty {
      color: $hover;
    }
  }
  &.outline {
    border: 2px solid $bg;

    @include desktop {
      &:hover,
      &:focus {
        border-color: $hover;
      }
    }
  }
  &.iconButton {
    .icon {
      //color: transparentize($text,0);
      color: $bg;

      &.icon-filled {
        background-color: $avatarBg;
      }
      &.icon-outline {
        background: none;
        border: 2px solid transparentize($avatarBg, 0.3);
        color: $avatarBg;
        & > svg {
          width: 22px;
          height: 22px;
        }
      }
      &.icon-empty {
        margin-right: rem(4px);
        background: none;
        color: $avatarBg;
      }
    }
  }
}

.default {
  @include format-button(
    color("text", "dark"),
    color("text", "dark"),
    color("grey", "lighter"),
    color("grey", "lightest"),
    color("grey", "base"),
    color("grey", "lightest")
  );
}
.primary {
  @include format-button(
    #fff,
    color("text", "primary"),
    color("primary"),
    color("primary", "light"),
    color("primary", "lightest"),
    color("primary", "lightest")
  );
}
.secondary {
  @include format-button(
    #fff,
    color("text", "secondary"),
    color("secondary"),
    color("secondary", "light"),
    color("secondary", "dark"),
    color("secondary", "lightest")
  );
}
.success {
  @include format-button(
    #fff,
    color("success"),
    color("success"),
    color("success", "light"),
    color("success", "dark"),
    color("success", "lightest")
  );
}
.info {
  @include format-button(
    #fff,
    color("info"),
    color("info"),
    color("info", "light"),
    color("info", "dark"),
    color("info", "lightest")
  );
}
.warning {
  @include format-button(
    #fff,
    color("warning", "dark"),
    color("warning"),
    color("warning", "light"),
    color("warning", "dark"),
    color("warning", "lightest")
  );
}
.danger {
  @include format-button(
    #fff,
    color("danger"),
    color("danger"),
    color("danger", "light"),
    color("danger", "dark"),
    color("danger", "lightest")
  );
}

@import "../utils/color";

@mixin subtle_scrollbar {
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: color(grey, light);
    border-radius: 3px;
    border: 0;
  }
}

@mixin subtle_horizontal_scrollbar {
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: color(grey, light);
    border-radius: 3px;
    border: 0;
  }
}

.initial-loader .unsupported-browser {
  display: block;
  background: #fff;
  max-width: 720px;
  margin: 60px auto;
  padding: 60px;
  border-radius: 4px;
  color: #212b36;
  text-align: center;
  box-sizing: content-box;
}
.unsupported-browser h1 {
  color: #231465;
}
.unsupported-browser .supported-browser-list {
  margin: 32px auto;
  list-style: none;
}
.supported-browser-list .browser {
  display: inline-block;
  width: 136px;
  height: 136px;
  margin: 0 16px 16px 0;
  background: #f2f2f0;
  border-radius: 6px;
  text-align: left;
}
.supported-browser-list .browser a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px;
  text-decoration: none;
  cursor: pointer;
  color: #212b36;
}
.supported-browser-list .browser a:visited {
  color: #212b36;
}
.supported-browser-list .browser img {
  padding: 0 16px 0 16px;
}
.supported-browser-list .browser h3 {
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}

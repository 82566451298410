@import "styles/common";

.timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  .marker:last-child {
    .iconContainer:before {
      width: 0;
    }
  }
  &.condensed {
    .marker {
      .iconContainer {
        padding-top: rem(4px);
        .icon {
          height: 28px;
          width: 28px;
          svg {
            font-size: 20px;
          }
        }
      }
      .textContainer {
        margin: 0 0 rem(6px) rem(4px);
        padding: rem(2px) rem(6px);
        .heading {
          font-size: rem(16px);
        }
        .description {
          font-size: rem(13px);
          line-height: 1.4;
          padding: 0 0 rem(12px) 0;
        }
      }
    }
  }
}

.marker {
  display: flex;
  &:hover {
    .textContainer {
      background-color: rgba(color("grey", "lightest"), 0.6);
    }
  }
  .iconContainer {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-top: rem(6px);
    &:before {
      content: "";
      background-color: rgba(color("grey", "darkest"), 0.4);
      height: 100%;
      width: 2px;
      position: absolute;
      right: 50%;
      left: 50%;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      height: 32px;
      width: 32px;
      background-color: #fff;
      border: 2px solid color(gigas, dark);
      color: color(gigas);
      border-radius: 50%;
      svg {
        font-size: 20px;
      }
    }
  }
  .textContainer {
    width: 100%;
    margin: 0 0 rem(12px) rem(6px);
    padding: rem(8px);
    border-radius: rem(6px);
    transition: background-color 100ms ease-in-out;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .heading {
        margin: 0;
        min-width: 60%;
      }
      .timestamp {
        color: color("text");
        font-weight: $fontMedium;
      }
    }
    .description {
      color: color("text", "light");
      padding: rem(4px) 0 rem(12px) 0;
    }
  }
}

@mixin format-timeline-marker($color, $border, $background) {
  &.marker {
    .icon {
      background-color: $background;
      border: 2px solid $border;
      color: $color;
    }
  }
}
.success {
  @include format-timeline-marker(
    color("success", "dark"),
    color("success", "dark"),
    lighten(color("success", "lightest"), 2)
  );
}
.info {
  @include format-timeline-marker(
    color("info", "dark"),
    color("info", "dark"),
    lighten(color("info", "lightest"), 2)
  );
}
.warning {
  @include format-timeline-marker(
    darken(color("warning", "dark"), 6),
    color("warning", "dark"),
    lighten(color("warning", "lightest"), 2)
  );
}
.danger {
  @include format-timeline-marker(
    color("danger", "darker"),
    color("danger", "darker"),
    lighten(color("danger", "lightest"), 2)
  );
}
.grey {
  @include format-timeline-marker(
    color("grey", "base"),
    color("grey", "base"),
    lighten(color("grey", "lightest"), 2)
  );
}

@use "sass:math";
@import "styles/common";

/* Remove default Radio Buttons */
.input:not(:checked),
.input:checked {
  position: absolute;
  left: -9999px;
  clip-path: polygon(0 0);
}

$line: color("grey", "light");

$transition: 0.28s ease;
$radius: rem(14px);
// Labels
.radio {
  position: relative;
  padding-left: rem(32px);
  cursor: pointer;
  display: block;
  min-height: rem(28px);
  line-height: rem(28px);
  transition: $transition;
  user-select: none;
  color: color(primary);
  margin-bottom: rem(6px);

  .label {
    color: color(text);
    cursor: pointer;
  }
  /* Disabled style */
  &.disabled {
    cursor: not-allowed;

    &,
    .label {
      color: $line;
    }

    .dot:before {
      background-color: transparent;
      border-color: $line;
      cursor: default;
    }
    .dot:after {
      background-color: $line;
      border-color: color(text);
    }
  }
}

.dot {
  position: absolute;
  left: 0;
  top: 0;
  margin: 7px;
  width: $radius;
  height: $radius;
  z-index: 0;

  /* Unchecked styles */
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: $radius;
    height: $radius;
    transition: $transition;
    border-radius: math.div($radius, 2);
  }
  &:before {
    border: rem(1px) solid color("grey", "light");
    background: color("grey", "lightest");
  }
  &:after {
    border: 1px solid color("grey", "light");
    z-index: -1;
    transform: scale(0);
  }
}

.radio {
  .input:checked {
    ~ {
      .dot {
        &:before {
          border: rem(1px) solid transparent;
        }

        &::after {
          border: rem(1px) solid currentColor;
          background-color: currentColor;
          z-index: 0;
          transform: scale(1.02);
        }
      }
      .label {
        font-weight: $fontBold;
      }
    }
  }
  &:hover,
  .input:focus ~ {
    .label {
      color: color("text", "primary");
    }
    .dot {
      &:before {
        border-width: 2px;
        box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.15);
        transform: scale(1.2);
      }
    }

    .input:checked ~ .dot {
      &:before {
        border-width: 0;
      }
      &::after {
        transform: scale(1.2);
      }
    }
  }
}

@import "styles/common";

.root {
  //background-color: #edeff3;
  //background-color: #f0f1f3;
  & > main {
    @include flex--column();
  }
}

.tabContent {
  position: relative;
  @include huge {
    max-width: $containerMax;
    margin-left: auto;
    margin-right: auto;
    min-width: initial;
  }
  .tab {
    font-size: rem(14px);
    //padding-bottom: 0 !important;
  }
}

.contentWrapper {
  background-color: #fff;

  min-height: calc(100vh - 250px);
  flex-grow: 1;
  padding-top: 24px;
}

.content {
  padding: 14px 0;

  & > h2 {
    margin: 0;
    font-weight: $fontBold;
    color: color(gigas);
  }
  & > p {
    margin-top: 0;
    font-size: rem(16px);
    //font-weight: $fontMedium;
  }
  @include desktop {
    padding: 24px 0;
  }
}
.loader {
  color: color(gigas);
}
.actions {
  @include flex--row(flex-start, flex-end);
  & > div:nth-child(1) {
    margin-right: 4px;
  }
}

@import "styles/common";

$navHoverBackgound: lighten(color(gigas, background), 5%);

.root {
  width: 50px;
  flex-shrink: 0;
  @include absolute-cover;
  pointer-events: auto;
  &.desktop {
    @include desktop {
      position: relative;
    }
  }
  @include desktop {
    transition-property: width, margin-right;
    transition-timing-function: cubic-bezier(0.72, -0.285, 0.275, 1.435);
    transition-duration: 0.5s;
  }
  @media print {
    display: none;
  }

  &.expanded {
    width: 320px;
    margin-right: -20px;
    z-index: -1;
    .expander {
      margin-right: 20px;
    }
    .navItem:hover {
      border-radius: 0 30px 30px 0;
    }
    .sticky {
      width: 320px;
    }
    .listSection {
      margin: 8px 22px 4px 0;
      &.sectionHasText {
        margin: 32px 22px 4px 0;
      }
      .listSectionLabel {
        opacity: 1;
      }
    }
  }
  &:not(.expanded) {
    @include desktop {
      .tooltip {
        z-index: 20;
      }
    }
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 50px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 14px 0;
    overflow-x: hidden;
    transition-property: width, margin-right;
    transition-timing-function: cubic-bezier(0.72, -0.285, 0.275, 1.435);
    transition-duration: 0.5s;
    @include desktop {
      position: sticky;
    }
  }
}
.expander {
  margin-right: 0;
  transition: all 0.5s;
}

.logoContainer {
  margin: 0 5px 8px 5px;
  display: flex;
  height: 44px;
  .logo {
    min-width: 40px;
    width: 40px;
    font-size: 32px;
    margin: 10px 0 12px 0;
    //margin: 0px 5px 8px 5px;
    flex-shrink: 0;
  }
  .logoText {
    margin-top: 3px;
    width: 150px;
    min-width: 150px;
  }
}

.navItemContainer {
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: calc(100vh - 30px);
  @include subtle-scrollbar;
}
.listSection {
  position: relative;
  text-decoration: none;
  list-style: none;
  background-image: linear-gradient(
    to right,
    rgba(#fff, 0.25) 90%,
    rgba(#fff, 0)
  );
  height: 1px;
  display: flex;
  align-items: flex-end;
  transition: margin 300ms ease-in-out;
  margin: 4px 0;
  width: 305px;

  .listSectionLabel {
    color: rgba(#fff, 0.5);
    transition: opacity 300ms ease-in;
    opacity: 0;
    min-width: 400px;
    margin: 0 0 0 rem(16px);
  }
}

.navItem {
  height: 48px;
  //padding: 8px 0;
  color: color("grey", "lighter");
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  transition: 500ms ease-in-out;
  .navItemIcon {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
    flex-shrink: 0;
  }
  .navItemText {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.02em;
  }
  &:hover {
    color: color(gigas, lightest);
    background: $navHoverBackgound;
    text-decoration: none;
    .navItemText {
    }
  }
}
.active {
  .navItem {
    color: color(primary, light);
    font-weight: bold;
    &:hover {
      color: color(primary, lighter);
    }
  }
}

.tooltip {
  background: $navHoverBackgound !important;
  height: 48px;
  //padding: 14px 24px 12px 12px !important;
  padding: 0 !important;
  border-radius: 0 24px 24px 0 !important;
  pointer-events: auto !important;

  a {
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 24px 0 12px;
    color: color(gigas, lightest);
    font-size: $fontSize;
    pointer-events: auto;
    &.active {
      color: color(primary, lighter);
      font-weight: bold;
    }
  }
}

// Containers
$containerWidth: 88%; // Percentage of screen width
$containerMax: 1400px; // Largest fixed width
$textContainer: 640px; // Limit texts for readability

// Media queries
$tabletWidth: 600px !default; // Tablets
$desktopWidth: 992px !default; // Desktops & Laptops
$hugeWidth: 1400px !default; // Large Desktops




@import "styles/common";
@import "../chart-palettes";

.root {
  background-color: transparentize(#fff, 0.1);
  //border
  color: color(text);
  min-width: 120px;
  padding: 12px;
}

.title {
  font-weight: $fontMedium;
  margin-top: 0;
}

.legend {
  @include flex(flex-start, stretch, column);
}
.item {
  @include flex(center, flex-start, row);
  width: 100%;
  span {
    color: #cccccc;
    background-color: currentColor;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
  .label {
    flex-grow: 1;
    margin-right: 12px;
    font-weight: $fontMedium;
  }
  .value {
    align-self: flex-end;
    i {
      color: color(text, light);
    }
  }
}

.sunsetTheme {
  @include chart-theme($chart-pallete-sunset-6, "dot");
}

.oceanTheme {
  @include chart-theme($chart-pallete-ocean-6, "dot");
}

@import "styles/common";

.menu {
  transition: color 0.15s, background-color 0.15s, width 0.25s ease-in-out;
  position: absolute;
  min-width: 160px;
  max-width: 320px;
  padding: 6px 0;
  list-style: none;
  background-clip: padding-box;
  border-radius: 12px 4px 12px 4px;
  box-shadow: 0 2px 6px rgba(28, 27, 35, 0.15);
  margin: 0;
  background-color: white;
  color: color("text");
}
.menuToggle {
  padding: 0;
}

.item {
  list-style: none;
  display: block;
  padding: 4px 8px 4px 16px;
  overflow: hidden;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: rem(14px);
  line-height: 1.5;
  cursor: pointer;

  &,
  & > button,
  & > a {
    color: color("text", "dark");
    transition: 300ms ease-in-out;
    transition-property: color, font-weight, background-color;
    font-weight: $fontNormal;
    text-align: center;
    box-sizing: border-box;
  }
  & > a {
    display: inline-block;
  }
  &:hover {
    &,
    & > button,
    & > a {
      font-weight: $fontBold;
      color: color("primary", "darker");
    }
    background-color: color("primary", "lightest");
  }
}

.arrow {
  top: 100%;
  left: 47.3828px;
  position: absolute;
  will-change: top, bottom, left, right;
  transform: translateX(-50%);
  path {
    fill: rgba(color("grey", "darkest"), 0.9);
  }
}

@import "styles/common";
@import ".error-types";

.errors {
  @include errors();
}

.tipWarning {
  background-color: color(info, lightest);
  color: color(info, darker);
}
.tipError {
  background-color: color(warning, lightest);
  color: color(warning, darker);
}
.tipCritical {
  background-color: color(danger, lightest);
  color: color(danger, darker);
}

@import "styles/common";

$a-wave: 72s;
$b-wave: 48s;
$s-wave: 64s;

.ocean {
  //position: relative;
  //height: 60px;
  //width: 100%;
  //overflow: hidden;
}
.wave {
  position: absolute;
  bottom: 0;
  height: 75%;
}
.front {
  z-index: 2;
  animation: wave $a-wave cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  &.second {
    animation: wave2 $a-wave cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  }
}

.swell {
  animation: wave $b-wave cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell $s-wave ease -1.25s infinite;
  opacity: 0.8;
  &.second {
    animation: wave2 $b-wave cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell $s-wave ease -1.25s infinite;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -100%;
  }
}

@keyframes wave2 {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -12px, 0);
  }
  50% {
    transform: translate3d(0, 8px, 0);
  }
}

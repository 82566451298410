@import "styles/common";

.toast {
  width: 300px;
  background: white;
  margin: 6px;
  flex: 0 0 20px;
  position: relative;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.176) 0 3px 8px;
  display: flex;
  .toastContent {
    flex-grow: 1;
    padding: 12px 0 12px 12px;
    font-weight: $fontMedium;
    white-space: pre-line;
  }
  .close {
    flex-shrink: 0;
    padding: 6px 8px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    & > svg {
      transition: 100ms ease-in-out;
      transition-property: transform, opacity;
      opacity: 0.6;
      font-size: rem(16px);
    }
    &:hover {
      & > svg {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
  .progressWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
  }
  .progress {
    background: color("text");
    height: 3px;
    width: 100%;
    border-radius: 2px;
    opacity: 0.6;
  }
}
.portal {
  max-height: 100%;
  overflow: visible;
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: flex-end;
}

@mixin format-toast($text, $background) {
  &.toast {
    background: $background;
    color: $text;
    .close {
      color: $text;
    }
    .progress {
      background: $text;
    }
  }
}

.default {
  @include format-toast(color("text"), #fff);
}
.primary {
  @include format-toast(color("text", "primary"), #fff);
}
.secondary {
  @include format-toast(color("text", "secondary"), #fff);
}
.success {
  @include format-toast(color("success", "dark"), color("success", "lightest"));
}
.info {
  @include format-toast(color("info", "darker"), color("info", "lightest"));
}
.warning {
  @include format-toast(
    color("warning", "darker"),
    color("warning", "lightest")
  );
}
.danger {
  @include format-toast(color("danger", "darker"), color("danger", "lightest"));
}

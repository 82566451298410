@import "styles/common";
$radius: 8px;
.label {
  //margin-bottom: 2pt;
  display: block;
  text-align: left;
  color: color("grey", "dark");
  font-size: 0.9em;
  font-weight: $fontBold;

  //padding-left: 14px;
  span {
    color: color(text);
    font-weight: normal;
    font-style: italic;
  }
}

// Plain inputs and textarea
.input {
  &,
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"],
  &[type="date"],
  &.area {
    appearance: none;
    //border: 1px solid rgba(color("grey", "lightest"), 1);
    border: 1px solid #eee;
    transition: 0.7s;
    padding: 12px 18px;
    border-radius: $radius;
    background-color: rgba(color("grey", "lightest"), 0.6);
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    color: color(text, dark);
    font-family: $fontBody;
    font-weight: $fontNormal;
    font-size: 1.05em;

    &::placeholder {
      color: lighten(color(text), 30%);
      font-size: 0.9em;
    }
    &:focus {
      border-color: transparentize(color(primary), 0.5);
      outline: 0;
      background-color: $background;
    }
    &.alternative:focus {
      border-color: transparentize(color(secondary), 0.5);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: color("grey", "lighter");
      color: color("text");
    }
  }
}
.rounded {
  .input {
    border-radius: 30px;
  }
  .label {
    padding-left: 12px;
  }
}

.hint {
  font-size: 0.8em;
  padding-left: 10pt;
  margin-bottom: 1.4em;
  letter-spacing: 0.03em;
  text-align: left;
  overflow-y: hidden;
  display: flex;
  align-items: center;
}

.error {
  .label {
    color: color("text", "danger");
    font-weight: bold;
  }
  .input {
    border-color: transparentize(color(danger), 0.5);
  }
}

.passwordWrapper {
  position: relative;
  .input {
    padding-right: 64px;
  }
  .toggle {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 12px;
    height: 24px;
    width: 24px;
    padding: 0;
    color: color(text);
    svg {
      max-width: 100%;
    }
    &:hover,
    &:focus {
      color: color(secondary, dark);
    }
    &.toggleLabel {
      top: 32px;
    }
  }
}
.area {
}
.iconInput {
  position: relative;
  .icon {
    pointer-events: none;
    left: 16px;
    top: 22px;
    height: 42px;
    position: absolute;
    display: flex;
    align-items: center;
    width: 24px;
    color: color(grey);
    transition: color 0.7s;
  }
  .input {
    padding-left: 48px;
    &:focus ~ .icon {
      color: color(primary);
    }
  }
}

.skeleton {
  .label {
    position: relative;
    width: 40%;
  }
  .input {
    height: 42px;
    position: relative;
    padding: 0;
  }
}
.condensed {
  &,
  &.skeleton,
  &.iconInput {
    .input {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .icon {
      height: 38px;
    }
  }
  &.skeleton {
    .input {
      height: 38px;
    }
  }
}

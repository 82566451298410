// Generic variables
@import "palette";
@import "../utils";

$background: #fff;
$backgroundAlt: color(grey, lightest);
$backgroundGradient: linear-gradient(
    180deg,
    color(gigas, background) 0%,
    color(gigas, bg-dark) 100%
  ),
  color(primary, background);

// Containers
$containerWidth: 88%; // Percentage of screen width
$containerMax: 1400px; // Largest fixed width
$textContainer: 640px; // Limit texts for readability

@import "styles/common";

.contentWrapper {
  background-color: #fff;
  min-height: calc(100vh - 250px);
  flex-grow: 1;
  //margin-top: -16px;
  //padding-top: 24px;
}
.content {
  padding: 0 0 14px 0;

  h2 {
    margin: 0;
    font-weight: $fontBold;
    color: color(gigas);
  }
  p {
    margin-top: 0;
    font-size: rem(16px);
    //font-weight: $fontMedium;
  }
  @include desktop {
    padding: 0 0 24px 0;
  }
}
.pageGauge {
  background-color: color(grey, lighter);
}
.headlineContainer {
  background-color: rgba(color(grey, lightest), 0.5);
  position: relative;
  margin-bottom: 40px;
  .headline {
    padding-top: 40px;
    @include flex--column(center, center);
    max-width: 1000px;
    @include desktop {
      padding-top: 48px;
      @include flex--row(stretch, center);
    }
    .headlineText {
      max-width: 100%;
      @include desktop {
        width: 50%;
      }
    }
    .headlineChart {
      position: relative;
      //width: 100%;
      margin-top: 26px;
      overflow-y: hidden;
      flex-shrink: 0;
      max-height: inherit;
      @include desktop {
        margin-top: 0;
        margin-left: 42px;
        width: 50%;
      }
      //flex-grow: 1;
    }

    //& > div {
    //  width: 50%;
    //}
  }
  .headlineWave {
    position: absolute;
    bottom: -164px;
    left: 0;
    right: 0;
    color: rgba(color(grey, lightest), 0.5);
  }
}

.demoTitle {
  font-weight: $fontBold;
  font-size: rem(24px);
}
.card {
  width: 100%;
  padding: 18px;
  box-shadow: 0 3px 8px 0 rgba(186, 199, 213, 0.5);
  border-radius: 12px;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 42px;
  .title {
    margin: 0;
    font-weight: $fontMedium;
    color: color(text);
  }
  p {
    margin-top: 0;
    padding-top: 0;
    color: color(text, light);
  }
}

.chartsWrapper {
  width: 100%;
  @include flex--row(center, center, wrap);
  & > div {
    padding: 0 16px;
  }
}
.chart,
.chart2 {
  h4 {
    font-weight: $fontBold;
  }
}

.chart2 {
  width: 100%;
  @include desktop {
    width: 50%;
  }
  & > div {
    position: relative;
    min-height: 200px;
  }
}
.chart {
  width: 100%;
  & > div {
    position: relative;
    width: 100%;
    min-height: 200px;
  }
  @include desktop {
    width: 50%;
  }
  @include huge {
    max-width: 33%;
  }
}
.sankeyWrapper {
  @include absolute-cover;
  & > div {
    @include absolute-cover;
    &:nth-child(2) {
      left: 20px;
      right: 20px;
      width: calc(100% - 40px) !important;
    }
  }
}
.sankey {
  text {
    fill: color(grey, darkest);
    paint-order: stroke;
    stroke: #fff;
    stroke-opacity: 0.5;
    stroke-width: 3px;
    opacity: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
    font-size: rem(13px);
    font-weight: $fontMedium;
  }
}
.sankeyLink {
  mix-blend-mode: inherit;
}

.changeTypeWrapper {
  @include flex--row(center, center);
  div {
  }
  .bigNum {
    font-weight: $fontBold;
    font-size: rem(42px);
  }
}

@import "styles/common";

.root {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  flex-shrink: 0;
  font-size: rem(22px);
  fill: currentColor;
}

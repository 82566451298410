@use "sass:math";
@mixin absolute-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin hidden {
  display: none;
  visibility: hidden;
}

@mixin truncate($max-width: false) {
  @if $max-width {
    max-width: $max-width;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin align($vertical: true, $horizontal: false, $position: relative) {
  @if $position {
    position: $position;
  }
  @if $vertical {
    top: 50%;
  }
  @if $horizontal {
    left: 50%;
  }

  @if $vertical and $horizontal {
    transform: translateX(-50%) translateY(-50%);
  } @else if $vertical {
    transform: translateY(-50%);
  } @else {
    transform: translateX(-50%);
  }
}

@mixin column-layout($num-columns: 12) {
  display: flex;
  flex-direction: row;
  @for $i from 1 through $num-columns {
    .col-#{$i} {
      flex-basis: #{(math.div($i,$num-columns) * 100) + "%"};
      max-width: #{(math.div($i,$num-columns) * 100) + "%"};
    }
  }
}

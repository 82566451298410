@import "../utils/rem";

// Typography
$fontBody: "Montserrat", sans-serif;
$fontHeadings: "Montserrat", sans-serif;

//$fontSize: 1.6rem;
$fontSize: rem(14px);
$fontLineHeight: 1.5;

$fontLight: 400;
$fontNormal: 500;
$fontMedium: 600;
$fontBold: 700;

$headingFontSizes: (
  "h1": rem(26px),
  "h2": rem(24px),
  "h3": rem(20px),
  "h4": rem(18px),
  "h5": rem(16px),
  "h6": rem(14px),
);

$headingDesktopFontSizes: (
  "h1": rem(34px),
  "h2": rem(28px),
  "h3": rem(22px),
);

@import "styles/common";
.root {
  z-index: -2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 750px;
  mask-image: linear-gradient(to right, transparent, black 150px);

  @include desktop {
    opacity: 0.5;
  }
  @media print {
    display: none;
  }
}

@import "styles/common";

$prefixWidth: 48px;
.phoneWrapper {
  position: relative;
  .prefix {
    position: absolute;
    left: 1px;
    top: 22px;
    height: 41.5px;
    width: $prefixWidth;
    padding: 0;
    color: color("text", "light");
    background: color("grey", "lighter");
    border-radius: 7px 0 0 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    //position: relative;
    //&::after{
    //  content: "";
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  height: 100%;
    //  width: $prefixWidth;
    //
    //  background: color('grey','lighter');
    //}
    padding-left: $prefixWidth + 6 !important;
  }
}

@use "sass:math";
@import "styles/common";

.root {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
}

.input {
  position: absolute;
  left: -9999px;
  clip-path: polygon(0 0);
}
.label {
  padding-right: 4px;
}
$height: 28px;
$width: $height * 2;
$padding: 2px;

.toggle {
  isolation: isolate;
  position: relative;
  display: inline-block;
  height: $height;
  width: $width;
  border-radius: math.div($height, 2);
  overflow: hidden;
  background-color: color("grey", "lightest");
  //box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
  //  4px 4px 4px 0px #d1d9e6 inset, -4px -4px 4px 0px #ffffff inset;
  box-shadow: 4px 4px 4px 0 color("grey", "lighter") inset;
}
.toggleInner {
  position: relative;
  overflow: hidden;
  height: $height - (2 * $padding);
  width: $width - (2 * $padding);
  margin: $padding auto;
  border-radius: $height - $padding;
}
.indicator {
  height: $height - (2 * $padding);
  width: 200%;
  //background: #ecf0f3;
  border-radius: $height - $padding;
  //transform: translate3d(-76%, 0, 0);
  transform: translate3d(-76%, 0, 0);
  transition: 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  transition-property: background-color, transform;
  //box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
  //box-shadow: 8px 4px 12px 0px #d1d9e6, -8px -4px 8px 0px #fff;
  background-color: color("danger");
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0
    (math.div($height - (2 * $padding) - (math.div($height, 3) * 2), 2) + 0.5);
  color: #fff;
  & > svg {
    font-size: math.div($height, 3) * 2;
    //opacity: 0.9;
  }
}

.input:checked ~ .toggle .indicator {
  transform: translate3d(26%, 0, 0);
  background-color: color("success");
}
.disabled {
  cursor: not-allowed;
}
.input:disabled ~,
.disabled {
  .toggle .indicator {
    background-color: color(grey);
  }
}

@import "../config/typography";
@import "../config/vars";
@import "../utils";
@import "../shared";

html {
  min-height: 100vh;
}

body {
  font: $fontNormal $fontSize $fontBody;
  color: color(text);
  line-height: $fontLineHeight;
  //letter-spacing: 0.01em;
  background: $backgroundGradient;
  @include subtle_scrollbar;
  z-index: -2;
  @media print {
    background: #fff;
  }
  &.noscroll {
    overflow: hidden;
  }
}

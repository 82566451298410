@import "styles/common";

.card {
  width: 100%;
  min-height: fit-content;
  min-width: fit-content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: rem(16px);
  &.normalShadow {
    box-shadow: $mediumShadow;
  }
  &.softShadow {
    box-shadow: 0 3px 8px 0 rgba(186, 199, 213, 0.5);
  }
}

.link {
  cursor: pointer;
  .card {
    &:hover {
      transform: scale(1.05);
    }
  }
}

.cardBg {
  @include absolute-cover;
}

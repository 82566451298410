@import "styles/common";

.root {
  display: flex;
  flex-direction: row;
  margin-bottom: rem(6px);
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  @include desktop {
    justify-content: start;
    margin-bottom: rem(16px);
  }
}
.filter {
  margin: 0 6px 6px 0;
  @include desktop {
    margin: 0 12px 12px 0;
  }
}
.wideFilter {
  min-width: 420px;
  min-height: 42px;
}

@import "styles/common";

.root {
  min-width: fit-content;
  min-height: fit-content;
  position: relative;
  margin: 10px;
}

.root {
  width: fit-content;
  //max-width: fit-content;
  min-height: fit-content;
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-shadow: $mediumShadow;
  padding: rem(16px);

  display: flex;
  flex-direction: column;
  transition: transform 200ms ease-in-out;
  &:hover {
    transform: scale(1.05);
    .title {
      background: transparentize(color("secondary"), 0.8);
    }
    .main {
      svg {
        color: color("primary", "dark");
      }
    }
  }
}

.cardBg {
  @include absolute-cover;
}

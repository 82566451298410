@import "styles/common";

.expander {
  cursor: pointer;
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px;
  overflow: hidden;
}

.expanderTextWrapper {
  position: relative;
  left: 50%;
  display: flex;
  align-items: center;
  letter-spacing: 1px;

  transform: rotate(-90deg);
  font-family: $fontBody;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  white-space: nowrap;
  color: color("grey", "lighter");
  min-width: 20px;
}

.expanderText {
}

.expanderIcon {
  font-size: 20px;

  &.rotateIcon {
    transform: rotate(180deg);
  }
}

@import "styles/common";

@mixin errors {
  display: flex;
  justify-content: space-around;
  font-weight: $fontMedium;
  text-align: center;
  span,
  div {
    &:nth-child(1) {
      color: color(info, dark);
    }
    &:nth-child(2) {
      color: color(warning, dark);
    }
    &:nth-child(3) {
      color: color(danger, dark);
    }
  }

  .errorIcon {
    position: relative;
    height: 1.6rem;
    margin-bottom: 6px;
  }
}

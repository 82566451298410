@import "styles/common";
.root {
  &.rounded {
    border-radius: 50px;
  }

  &.disabled {
    pointer-events: none;
  }
  width: 100%;
  @include flex--row();
  overflow: hidden;
  background-color: color(grey, light);
  //margin-top: 12px;
}

.bar {
  height: 100%;
}

@import "styles/common";

$z-bg: 1;
$z-bg-overlay: 2;
$z-midground: 3;
$z-foreground: 4;
$z-content: 10;
$z-content-pop: 11;
$z-content-overlay: 12;
$z-content-text: 13;

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;
  @include desktop {
    flex-direction: row;
  }
}

.logo {
  z-index: $z-foreground;
  margin: 12px auto;
  width: 140px;
  height: 40px;
  top: 0;
  .houseBrand {
    fill: color("primary", darkest);
  }
  .lightBrand {
    fill: color("primary");
  }
  @include desktop {
    position: fixed;
    width: 220px;
    height: auto;
    margin: 24px 48px;
    top: 0;
    right: 0;
  }
}
.logoLogo {
  position: absolute;
  top: 16px;
  right: 268px;
  width: 60px;
  font-size: 38px;
  z-index: $z-content-pop;
  display: none;
  path {
    fill: color(primary);
    &:nth-child(2) {
      fill: color(secondary, darker);
    }
    @include desktop {
      fill: color(primary);
      &:nth-child(2) {
        fill: color(secondary, darker);
      }
    }
  }
  @include desktop {
    position: fixed;
    display: inherit;
    top: 28px;
    right: 256px;
  }
}

.wavesBackgroundBg {
  position: relative;
  z-index: $z-bg-overlay;
  width: 200%;
  max-width: 200%;
  margin-left: -100%;
  @include desktop {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

.backgroundContainer {
  position: relative;
  margin: 0 auto;
  border-radius: 32vmin;
  width: 64vmin;
  height: 64vmin;
  max-width: 320px;
  max-height: 320px;
  overflow: hidden;
  pointer-events: none;

  //box-shadow: 0px 4px 12px rgba(163, 163, 163, 0.5);
  border: 4px solid color("primary", "background");
  background: linear-gradient(90deg, #6fd3f6 60.8%, #e4fbff 91.56%);

  @include desktop {
    position: static;
    margin: 0;
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    margin-left: -100%;
    background: linear-gradient(90deg, #6fd3f6 60.8%, #e4fbff 91.56%);
    @include desktop {
      position: fixed;
      margin-left: 0;
      width: 100%;
    }
  }
}

.contentContainer {
  position: relative;
  z-index: $z-content;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  pointer-events: auto;
  @include desktop {
    position: absolute;
    min-height: 100vh;
    min-width: 700px;
    width: 66%;
    border-top-right-radius: 40px;
    box-shadow: 0 24.8727px 49.7455px rgba(0, 0, 0, 0.3),
      0 19.6364px 15.7091px rgba(0, 0, 0, 0.22);
  }
  .padder {
    flex-grow: 1;
  }
  .desktopPadder {
    display: none;
    @include desktop {
      display: inherit;
      flex-grow: 1;
    }
  }

  .content {
    z-index: $z-content-text;
    width: 88%;
    max-width: $textContainer;
    margin: 24px auto 20vh auto;
    position: relative;
    overflow-y: auto;
    @include desktop {
      //background-color: rgba(#fff,0.6);
      //border-radius: 20px;
      margin: 40px auto -80px auto;
      width: 68%;
    }
  }
}
.wavesBg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 25%;
  //z-index: $z-content-pop;
  opacity: 0.9;
  @include desktop {
    position: relative;
    height: 30vh;
  }
}

.wavesOverlay {
  position: absolute;
  bottom: -4px;
  height: 80px;
  width: 1200px;
  z-index: $z-content-overlay;
  @include desktop {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 12vh;
  }
}

.img {
  position: fixed;
}
.bottle,
.kraken {
  .wavesBg {
    display: none;
  }
}

.bottle {
  .background {
    background: linear-gradient(90deg, #6fd3f6 60.8%, #b4effa 91.56%);
  }
  .img {
    bottom: -40px;
    left: 0;
    width: 50vmin;
    max-width: 260px;
    position: absolute;
    z-index: $z-content-pop;
    animation: float 3s ease-in-out infinite alternate;

    @include desktop {
      z-index: $z-content-pop;
      position: fixed;
      width: 30%;
      min-width: 500px;
      max-width: 700px;
      left: 56%;
      bottom: -35%;
      animation: floatDesktop 3s ease-in-out infinite alternate;
    }
  }
}
.lighthouse {
  .img {
    bottom: 24px;
    right: 0;
    width: 84%;
    height: auto;
    position: absolute;
    z-index: $z-content-pop;
    overflow: visible;
    @include desktop {
      bottom: 40px;
      width: 36%;
      max-height: 60vh;
    }
  }
  .clouds {
    right: 0;
    top: 20px;
    position: absolute;
    width: 100%;
    height: 40%;
    @include desktop {
      height: 22vh;
      width: 40vw;
      top: 60px;
    }
  }
}

.kraken {
  .background {
    background: linear-gradient(90deg, #120450 60.8%, #104785 91.56%);
  }
  .wavesOverlay {
    path {
      fill: url(#overlay_wave_linear_dark_568731);
    }
  }
  .img {
    z-index: $z-content-pop;
    position: absolute;
    height: auto;
    width: 80%;
    bottom: -12px;
    left: 10%;
    animation: menace 5s ease-in-out infinite alternate;
    @include desktop {
      position: fixed;
      width: 35%;
      min-width: 500px;
      left: 62%;
      bottom: -5%;
    }
  }
  .imgAlt {
    z-index: $z-foreground;
    position: absolute;
    height: auto;
    width: 83%;
    bottom: 10px;
    left: 16%;
    animation: menace 7s ease-out infinite alternate-reverse;
    @include desktop {
      position: fixed;
      width: 35%;
      left: 63%;
      bottom: -3%;
    }
  }

  @include desktop {
    .logoLogo {
      path {
        fill: #fff;
        &:nth-child(2) {
          fill: color(secondary, light);
        }
      }
    }
    .logo {
      .houseBrand {
        fill: #fff;
      }
      .lightBrand {
        fill: color("secondary", "light");
      }
    }
  }
}
.underwater {
  .background {
    background: linear-gradient(90deg, #1c9fd7 60.8%, #44ccd5 91.56%);
  }
  .wavesBackgroundBg {
    path {
      fill: rgba(47, 130, 255, 0.3);
    }
  }
  .wavesOverlay {
    path {
      fill: url(#overlay_wave_linear_alt_3452345);
    }
  }
  .img {
    z-index: $z-foreground;
    position: absolute;
    height: auto;
    width: 80%;
    bottom: 50px;
    left: -20%;
    animation: float 3s ease-in-out infinite alternate;
    @include desktop {
      position: fixed;
      width: 20%;
      left: 72%;
      bottom: 20vh;
    }
  }
  .imgAlt {
    z-index: $z-content-pop;
    position: absolute;
    height: auto;
    width: 20%;
    top: 40px;
    right: 21%;
    animation: menace 4s ease-in-out infinite alternate;
    @include desktop {
      position: fixed;
      width: 6%;
      left: 65%;
      right: inherit;
      top: 10vh;
    }
  }

  @include desktop {
    .logo {
      .houseBrand {
        fill: color("primary", "background");
      }
      .lightBrand {
        fill: #ffffff;
      }
    }
  }
}
.moon {
  .background {
    background: linear-gradient(100.91deg, #62f3f3 4.25%, #142a60 87.98%);
  }
  .wavesOverlay {
    path {
      fill: url(#overlay_wave_linear_dark_568731);
    }
  }
  .img {
    top: 24px;
    right: 0;
    width: 20%;
    height: auto;
    position: absolute;
    z-index: $z-content-pop;
    overflow: visible;
    @include desktop {
      top: 64px;
      width: 20%;
      max-height: 60vh;
    }
  }
  .clouds {
    right: 0;
    top: 20px;
    position: absolute;
    width: 100%;
    height: 40%;
    z-index: $z-content-pop;
    @include desktop {
      height: 22vh;
      width: 40vw;
      top: 60px;
    }
  }
  @include desktop {
    .logo {
      .houseBrand {
        fill: #fff;
      }
      .lightBrand {
        fill: color("secondary", "light");
      }
    }
  }
}
.data {
  .background {
    background: linear-gradient(
      32deg,
      #12034f 25.73%,
      #359d9e 74.56%,
      #cff4d2 91.41%
    );
  }
  .wavesOverlay {
    path {
      fill: url(#overlay_wave_linear_dark_568731);
    }
  }
  .img {
    z-index: $z-content-pop;
    position: absolute;
    height: 140%;
    width: 200%;
    bottom: -20%;
    right: 0;
    //animation: float 3s ease-in-out infinite alternate;
    @include desktop {
      position: fixed;
      height: auto;
      max-height: 86%;
      max-width: 52%;
      width: auto;
      bottom: 0;
      right: 0;
    }
  }
}

.letter {
  .background {
    background: linear-gradient(
      32deg,
      #3c306d 27.25%,
      #407cb4 62.68%,
      #68d7bc 91.39%
    );
  }
  .wavesOverlay {
    path {
      fill: url(#overlay_wave_linear_dark_568731);
    }
  }
  .img {
    z-index: $z-content-pop;
    position: absolute;
    height: 140%;
    width: 110%;
    max-width: 110%;
    bottom: -20%;
    right: 0;
    //animation: float 3s ease-in-out infinite alternate;
    @include desktop {
      position: fixed;
      height: auto;
      max-height: 92%;
      max-width: 56%;
      width: auto;
      bottom: 0;
      right: 0;
    }
  }

  @include desktop {
    .logoLogo {
      path {
        fill: color(primary, lightest);
        &:nth-child(2) {
          fill: color(primary, darker);
        }
      }
    }
    .logo {
      .houseBrand {
        fill: #fff;
      }
      .lightBrand {
        fill: color("primary", "darkest");
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(-12px);
  }
}

@keyframes floatDesktop {
  0% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(-40px);
  }
}

@keyframes menace {
  0% {
    transform: translateX(0px) translateY(0%);
  }
  25% {
    transform: translateY(-6px);
  }
  50% {
    transform: translateY(16px) translateX(8px);
  }
  75% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateX(-12px) translateY(0%);
  }
}

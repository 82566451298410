@import "styles/common";
@import "../chart-palettes";

.responsiveContainer {
  position: relative;
  .root {
    @include absolute-cover;
  }
}

.rowBar {
  opacity: 0.8;
  color: #ccc;
  fill: currentColor;
  border-radius: 50px;

  &:hover {
    opacity: 1;
  }
}

.rowLabel {
  fill: #fff;

  paint-order: stroke;
  stroke: color(grey, darkest);
  stroke-opacity: 0.2;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
  //mix-blend-mode: overlay;

  text-anchor: end;
  alignment-baseline: middle;
  &.outsideLabel {
    //mix-blend-mode: multiply;
    fill: color(text);
    stroke: #fff;
    stroke-opacity: 0.5;
    text-anchor: start;
  }
}

.oceanTheme {
  @include chart-theme($chart-pallete-ocean-6, "rowIndex");
}
.sunsetTheme {
  @include chart-theme($chart-pallete-sunset-6, "rowIndex");
}

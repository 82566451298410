@import "styles/common";

.option {
  list-style-type: none;
  padding: 6px 12px;
  transition: background-color 100ms ease-in-out;
  &.highlighted {
    background-color: color("grey", "lightest");
    &.active {
      background-color: rgba(color("primary", "lighter"), 0.4);
    }
  }
  &.active {
    font-weight: $fontBold;
    color: color("text", "primary");
    background-color: rgba(color("primary", "lightest"), 0.3);
    & > div {
      border: 1px solid color("primary", "lighter");
    }
  }
  &,
  & > button {
    cursor: pointer;
  }
}

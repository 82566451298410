@mixin print {
  @media print {
    @content;
  }
}

@mixin no-print {
  @include print {
    display: none;
    @content;
  }
}

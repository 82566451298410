@import "styles/common";

.root {
  position: absolute;
  right: 6px;
  top: 40px;
  //left: 110px;
  //top: 2px;
  color: rgba(color(grey), 0.4);
  font-weight: $fontMedium;
  font-size: rem(13px);
  //padding: 6px 6px 6px 12px;
  //border-radius: 16px 0 0 16px;
  //background-color: rgba(color(grey, lightest),0.5);
}

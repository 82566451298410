@import "styles/common";
@import "../common/.error-types";

.selected,
.previous,
.recent {
  font-weight: $fontBold;
  color: color(secondary, darker);
}
.selected {
  color: color(primary);
}
.previous {
  color: color(grey);
  margin-top: 84px;
}

.errors,
.loadingErrors {
  @include errors();
  div {
    min-width: 20px;
  }
}
.loadingErrors {
  margin-right: rem(-16px);
  .skeleton {
    width: 40px;
  }
}

.dangerIcon,
.successIcon,
.warnIcon,
.errorIcon,
.disabledIcon {
  font-size: rem(18px);
}
.successIcon {
  color: color(success, dark);
}
.warnIcon {
  color: color(info, dark);
}
.errorIcon {
  color: color(warning, dark);
}
.dangerIcon {
  color: color(danger, dark);
}
.disabledIcon {
  color: color(grey, dark);
}

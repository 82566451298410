@import "styles/common";

.root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, #3d3552 0%, #141327 100%), #3d3552;
  min-height: 100vh;
  min-width: 100vw;
}
.bg,
.fg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 72px;
  margin-top: -35px;
  margin-left: -36px;
}
.fg {
  animation: fg-spin 5000ms infinite linear;
}
@keyframes fg-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

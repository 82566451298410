@import "styles/common";

.formActions {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 16px;

  @include desktop {
    justify-content: flex-start;
    flex-direction: row-reverse;
    & > button {
      margin-left: 16px;
      cursor: pointer;
    }
  }
}

.error {
  margin: 12px auto;
  padding: 12px 16px;
  color: color("danger", "darker");
  background-color: color("danger", "lightest");
  font-weight: $fontBold;
  text-align: center;
  border-radius: 6px;
}

.verifyContainer {
  background: color("grey", "lightest");
  padding: 32px 48px;
  border-radius: 8px;
  margin-bottom: 24px;
  b {
    color: color("primary");
  }
}
.contact {
  font-weight: $fontBold;
  color: color("primary");
}
.passwordRules {
  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 4px 0;
    svg {
      margin-right: 6px;
      color: transparentize(color("danger", "dark"), 0.5);
    }
  }
  .success {
    &,
    svg {
      color: color("success");
    }
    font-weight: $fontMedium;
  }
}

@import "styles/common";
@import "skeleton_common";

.root {
  @include absolute-cover;
  @include skeleton-default;

  &.base {
    @include variant-options(color(grey, lighter));
  }
  &.dark {
    @include variant-options(color(grey, light));
  }
  &.light {
    @include variant-options(color(grey, lightest));
  }
  &.inverse {
    @include variant-options(#fff);
    opacity: 0.8;
  }
}

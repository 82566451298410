@import "styles/common";

.headline {
  @include flex--column();
  color: color(gigas, darker);
  @include desktop {
    &:not(.vertical) {
      @include flex--row(center);
    }
    &.vertical {
      .bigNumber {
        margin-right: 0;
      }
    }
  }
}

.bigNumber {
  font-weight: $fontBold;
  color: color(gigas);
  line-height: 1;
}

.bigSize {
  .bigNumber {
    font-size: rem(62px);
    @include desktop {
      font-size: rem(85px);
      margin-right: 18px;
    }
  }
}
.normalSize {
  .bigNumber {
    font-size: rem(48px);
    @include desktop {
      font-size: rem(62px);
      margin-right: 12px;
    }
  }
}
.smallSize {
  .bigNumber {
    font-size: rem(28px);
    @include desktop {
      font-size: rem(38px);
      margin-right: 8px;
    }
  }
}

.description {
  font-weight: $fontMedium;
  font-size: rem(16px);
  @include desktop {
    font-size: rem(18px);
  }
}

.highlight {
  color: #c14e83;
  font-weight: $fontBold;
}

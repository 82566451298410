@import "styles/common";
.root {
}
.header {
  margin-bottom: 24px;
  h2 {
    color: color("grey");
    margin-bottom: 0;
  }
  h1 {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .headerText {
    overflow: hidden;
  }
}
.fileDetailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.actions {
  margin-left: 14px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-shrink: 0;
  & > div {
    margin-bottom: 4px;
  }
  .statusLabel {
    font-weight: $fontBold;
    color: color("text");
    margin-right: 8px;
  }
}
.fileDetail {
  color: color("text", "dark");
  background-color: transparentize(color("grey", "lightest"), 0.5);
  border-top: 1px solid color("grey", "light");
  border-bottom: 1px solid color("grey", "light");
  padding: rem(16px) 0;
  @include desktop {
    padding: rem(20px);
  }
  .selected {
    width: 100%;
    margin-bottom: 8px;
    font-weight: $fontMedium;
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 6px;
    }
  }

  .label {
    min-width: 120px;
    color: color("text", "light");
  }
  .value {
    font-weight: $fontMedium;
  }
}
.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.headerAccordian {
  padding: 16px 0 8px 0;
  font-size: 1.44em;
}
.timelineContainer {
  background-color: rgba(#fff, 0.8);
  border-radius: 0 0 8px 8px;
  border: 1px solid color("grey", "lighter");
  border-top: none;
}
.timeline {
  padding: 16px 24px 4px 24px;
}
.timelineAccordian {
  padding: 0;
  color: color("text", "light");
  margin-bottom: 0 !important;
}
.subheader {
  color: color("text", "secondary");
  margin-bottom: 0;
  font-weight: $fontMedium;
}

.fileStatusHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
    justify-content: space-between;
  }
}
.rowScanned {
  display: flex;
  align-items: baseline;
  font-size: rem(18px);
  padding: 16px 0;
  div {
    font-weight: $fontBold;
    padding-right: 16px;
    font-size: rem(24px);
  }
}

.success {
  color: color("success", "dark");
}
.warning {
  color: color("info", "dark");
}
.error {
  color: color("warning", "dark");
}
.critical {
  color: color("danger", "dark");
}
.colStatus {
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 4px;
  }
}
.errors {
  display: flex;
  justify-content: space-around;
  font-weight: $fontMedium;
  text-align: center;
  span,
  div {
    &:nth-child(1) {
      color: color("info", "dark");
    }
    &:nth-child(2) {
      color: color("warning", "dark");
    }
    &:nth-child(3) {
      color: color("danger", "dark");
    }
  }

  .errorIcon {
    position: relative;
    height: 1.6rem;
    margin-bottom: 6px;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    padding: 0 32px;
    margin-bottom: 24px;
    h3 {
      color: color("primary");
      font-weight: $fontBold;
    }
  }
  .actions {
    flex-direction: row;
    justify-content: flex-end;
  }
}
